import { CourseModuleOutline as GraphQLCourseModuleOutline } from '@stellar-lms-frontend/lms-graphql';
import { createWithEqualityFn } from 'zustand/traditional';
import { Actions, State, CourseModuleOutline } from './types';
import { LearningActivityType } from '../../../utils/learningActivity';

const transformCourseModuleOutline = (
  original: GraphQLCourseModuleOutline,
): CourseModuleOutline => {
  const { id, title, stats, learningActivities, placeholder, description } = original;

  return {
    id: id,
    title: title ?? undefined,
    description: description ?? undefined,
    placeholder: placeholder ?? false,
    stats: stats
      ? {
          learnersCompleted: stats.learnersCompleted,
          learnersStarted: stats.learnersStarted,
        }
      : undefined,
    learningActivities: learningActivities
      ? learningActivities.map((activity) => ({
          commentCount: activity.commentCount ?? undefined,
          dateOpen: activity.dateOpen ?? undefined,
          description: activity.description ?? undefined,
          duration: activity.duration ?? undefined,
          enabled: activity.enabled ?? false,
          aiGenerated: activity.aiGenerated ?? false,
          aiGenerationDone: activity.aiGenerationDone ?? false,
          placeholder: activity.placeholder ?? false,
          type: activity.type ?? LearningActivityType.Unknown,
          id: activity.id,
          locked: activity.locked ?? false,
          prerequisites: activity.prerequisites
            ? activity.prerequisites.map((prerequisite) => ({
                mandatory: prerequisite.mandatory ?? false,
                module: prerequisite.module
                  ? {
                      id: prerequisite.module.id,
                      steps: prerequisite.module.steps
                        ? prerequisite.module.steps.map((step) => ({
                            id: step.id,
                            title: step.title,
                          }))
                        : [],
                      title: prerequisite.module.title ?? undefined,
                    }
                  : undefined,
              }))
            : [],
          progress: activity.progress ?? undefined,
          state: activity.state ?? undefined,
          steps: activity.steps
            ? activity.steps.map((step) => ({
                commentCount: step.commentCount ?? undefined,
                completed: step.completed ?? false,
                duration: step.duration ?? undefined,
                enabled: step.enabled ?? false,
                id: step.id,
                title: step.title ?? undefined,
                visited: step.visited ?? false,
              }))
            : [],
          title: activity.title ?? undefined,
        }))
      : [],
  };
};

export const useStore = createWithEqualityFn<State & Actions>((set) => ({
  modules: [],
  setModules: (modules) => set({ modules: modules.map((m) => transformCourseModuleOutline(m)) }),
}));
