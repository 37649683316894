import {
  Badge,
  CheckSmall,
  Heading1,
  Heading4,
  Modal,
  PrimaryButton,
} from '@stellar-lms-frontend/ui-components';
import { useTranslation } from 'react-i18next';
import { Fragment, useState } from 'react';
import { StripeCheckout } from './stripe-checkout';
import { Stripe } from '@stripe/stripe-js';

export const IndividualPlanCard = ({
  stripePromise,
}: {
  stripePromise: Promise<Stripe | null>;
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'components.individual-plan-card' });
  const [isStripeModalOpen, setIsStripeModalOpen] = useState(false);

  // Some fun to get an actual array from returnObjects..
  const includedFeatures = t<
    'included-features',
    { returnObjects: true; defaultValue: [] },
    string[]
  >('included-features', { returnObjects: true, defaultValue: [] });

  return (
    <>
      <div className="bg-surface-01 text-text-01 flex w-[380px] flex-col items-start gap-6 rounded-xl p-6 text-left shadow-md">
        <Badge color={'green'}>{t('badge')}</Badge>
        <div className="space-y-2">
          <Heading1 className="gradient-text">{t('title')}</Heading1>
          <Heading4 className="text-primary-01">{t('subTitle', { cost: '€50' })}</Heading4>
        </div>
        <div className="grow space-y-3">
          <span className="text-text-02 type-small-medium">{t('features')}</span>
          <div className="grid-cols-icon-content-large grid items-center gap-5">
            {includedFeatures.map((feature) => (
              <Fragment key={feature}>
                <CheckSmall /> <span>{feature}</span>
              </Fragment>
            ))}
          </div>
        </div>
        <PrimaryButton
          onClick={() => setIsStripeModalOpen(true)}
          label={t('call-to-action')}
        />
      </div>
      <Modal
        onClose={() => setIsStripeModalOpen(false)}
        isOpen={isStripeModalOpen}
        title={t('call-to-action')}
        className="bg-surface-01 h-[100vh] max-h-[1100px] w-[1080px]"
      >
        {isStripeModalOpen && (
          <StripeCheckout
            stripePromise={stripePromise}
            onComplete={() => setIsStripeModalOpen(false)}
          />
        )}
      </Modal>
    </>
  );
};
