import { LearningActivityStep } from '@stellar-lms-frontend/lms-api';
import {
  CreateQuizStepMutation,
  CurrentCompany,
  DesignerAssessmentStepQuery,
  DesignerAssessmentStepQueryVariables,
  MarkSuggestedQuestionAsAcceptedMutation,
  MarkSuggestedQuestionAsAcceptedMutationVariables,
  MarkTipViewedMutationVariables,
  MutationCreateQuizStepArgs,
  MutationUpdateQuizStepArgs,
  QuestionGenerationMutationVariables,
  SuggestedQuestions,
  SuggestedQuestionsQueryVariables,
  UpdateQuizStepMutation,
} from '@stellar-lms-frontend/lms-graphql';
import { useParams } from 'react-router-dom';
import AssessmentStepEdit from './assessment-step-edit';
import AssessmentStepView, { AssessmentStepViewProps } from './assessment-step-view';
import { xxHash } from '@stellar-lms-frontend/common-utils';

export type AssessmentActions = {
  edit?: {
    markTipViewed?: (variables: MarkTipViewedMutationVariables) => void;
  };
  assessment?: {
    create?: (variables: MutationCreateQuizStepArgs) => Promise<CreateQuizStepMutation>;
    update?: (variables: MutationUpdateQuizStepArgs) => Promise<UpdateQuizStepMutation>;
    fetch?: (
      variables: DesignerAssessmentStepQueryVariables,
    ) => Promise<DesignerAssessmentStepQuery>;
    fetchSuggestedQuestions?: (
      variables: SuggestedQuestionsQueryVariables,
    ) => Promise<SuggestedQuestions>;
    questionGeneration?: (variables: QuestionGenerationMutationVariables) => Promise<boolean>;
    markSuggestedQuestionAsAccepted?: (
      variables: MarkSuggestedQuestionAsAcceptedMutationVariables,
    ) => Promise<MarkSuggestedQuestionAsAcceptedMutation>;
  };
  publishedAssessment: AssessmentStepViewProps['actions'];
};

export type AssessmentStepProps = {
  currentCompany: CurrentCompany;
  isEditing?: boolean;
  lastLearningActivity?: boolean;
  step?: LearningActivityStep;
  actions: AssessmentActions;
  moduleId?: string; // TODO: consider adding it to the url courseId/moduleId/learningActivityId/stepId
};

export const AssessmentStep: React.FC<AssessmentStepProps> = ({
  isEditing,
  currentCompany,
  step,
  actions,
  moduleId,
}) => {
  const { learningActivityId, courseId } = useParams();

  if (!learningActivityId || !courseId || !moduleId) {
    return null;
  }

  if (isEditing) {
    return (
      <AssessmentStepEdit
        key={`${xxHash(JSON.stringify(step))}`}
        currentCompany={currentCompany}
        courseId={courseId}
        moduleId={moduleId}
        learningActivityId={learningActivityId}
        step={step}
        actions={actions}
      />
    );
  } else if (step) {
    return (
      <AssessmentStepView
        step={step}
        currentCompany={currentCompany}
        actions={actions.publishedAssessment}
      />
    );
  } else {
    return null;
  }
};
