import { GraphQLClient } from 'graphql-request';
import {
  getFragmentData,
  graphql,
  LearnerWorkBasedActionCompleteInput,
  MutationAddWorkBasedActionDiscussionItemArgs,
  MutationLikeWorkBasedActionDiscussionItemArgs,
  SurveyQuestionUserAnswer,
} from '@stellar-lms-frontend/lms-graphql';
import * as Sentry from '@sentry/react';
import {
  DiscussionItem,
  DISCUSSION_PAGE_SIZE,
  PublishedSurvey,
  PersistedLearnerWorkBasedActionWithUser,
} from '@stellar-lms-frontend/lms-api';
import {
  mapPersistedWBA,
  PersistedLearnerWorkBasedActionFragment,
} from '../designer/work-based-action/work-based-action.api';
import { sanitizeHtml } from '@stellar-lms-frontend/common-utils';

export const completeWorkBasedAction = (
  client: GraphQLClient,
  id: string,
  input: LearnerWorkBasedActionCompleteInput
): Promise<PersistedLearnerWorkBasedActionWithUser> => {
  return client
    .request(
      graphql(`
        mutation CompleteWorkBasedAction($id: ID!, $input: LearnerWorkBasedActionCompleteInput!) {
          completeWorkBasedAction(id: $id, wbaInput: $input) {
            ...PersistedLearnerWorkBasedActionFragment
          }
        }
      `),
      {
        id,
        input,
      }
    )
    .then((data) => {
      if (data.completeWorkBasedAction) {
        return mapPersistedWBA(
          getFragmentData(PersistedLearnerWorkBasedActionFragment, data.completeWorkBasedAction)
        );
      } else {
        const error = new Error('No workbased action returned after completing WBA');
        Sentry.captureException(error);
        throw error;
      }
    });
};

export const getDiscussionItems = (
  client: GraphQLClient,
  courseId: string,
  actionId: string,
  offset = 0
): Promise<DiscussionItem[]> => {
  return client
    .request(
      graphql(`
        query GetWBADiscussionItems($courseId: ID!, $actionId: ID!, $offset: Int, $pageSize: Int) {
          workBasedActionDiscussionItems(
            courseId: $courseId
            actionId: $actionId
            offset: $offset
            pageSize: $pageSize
          ) {
            totalItems
            items {
              id
              text
              nrOfLikes
              liked
              secondsSinceLastUpdate
              createdAt
              createdBy {
                id
                name
                thumbUri
              }
              replies {
                id
                text
                nrOfLikes
                liked
                secondsSinceLastUpdate
                createdAt
                createdBy {
                  id
                  name
                  thumbUri
                }
              }
            }
          }
        }
      `),
      {
        courseId,
        actionId,
        offset,
        pageSize: DISCUSSION_PAGE_SIZE,
      }
    )
    .then((data) => {
      return data.workBasedActionDiscussionItems.items.map((item: any) =>
        mapDiscussionItem(item, courseId, actionId)
      );
    });
};

export const getLearnerWorkBasedAction = (
  client: GraphQLClient,
  id: string
): Promise<PersistedLearnerWorkBasedActionWithUser> => {
  return client
    .request(
      graphql(`
        query GetLearnerWorkBasedAction($id: ID!) {
          learnerWorkBasedAction(id: $id) {
            ...PersistedLearnerWorkBasedActionFragment
          }
        }
      `),
      {
        id,
      }
    )
    .then((data) => {
      if (data.learnerWorkBasedAction) {
        return mapPersistedWBA(
          getFragmentData(PersistedLearnerWorkBasedActionFragment, data.learnerWorkBasedAction)
        );
      } else {
        const error = new Error('LearnerWorkBasedAction not found');
        Sentry.captureException(error);
        throw error;
      }
    });
};

export const getWbaSurveyResult = (
  client: GraphQLClient,
  wbaId: string,
  surveyId?: string
): Promise<PublishedSurvey | undefined> => {
  if (surveyId) {
    return client
      .request(
        graphql(`
          query WorkBasedActionSurveyResult($wbaId: ID!, $surveyId: ID!) {
            workBasedActionSurveyResult(wbaId: $wbaId, surveyId: $surveyId) {
              id
              title
              externalId
              description
              submitted
              submissionDate
              pagePerQuestion
              questions {
                id
                questionId
                type
                text
                answered
                visibleToMentors
                leftLabel
                rightLabel
                required
                answers {
                  id
                  answerId
                  text
                  other
                  userAnswered
                  userInput
                }
              }
            }
          }
        `),
        {
          wbaId,
          surveyId,
        }
      )
      .then((data) => {
        if (data.workBasedActionSurveyResult) {
          return mapPublishedSurvey(data.workBasedActionSurveyResult);
        } else {
          return undefined;
        }
      });
  } else {
    return Promise.resolve(undefined);
  }
};

export const submitWorkBasedActionLearnerSurveyWithTimeSpent = (
  client: GraphQLClient,
  id: string,
  surveyId: string,
  answers: SurveyQuestionUserAnswer[],
  timeSpent: number
): Promise<PersistedLearnerWorkBasedActionWithUser> => {
  return client
    .request(
      graphql(`
        mutation SubmitWorkBasedActionLearnerSurveyWithTimeSpent(
          $id: ID!
          $publishedSurveyId: ID!
          $answers: [SurveyQuestionUserAnswer!]!
          $timeSpent: Float!
        ) {
          submitWorkBasedActionLearnerSurveyWithTimeSpent(
            id: $id
            publishedSurveyId: $publishedSurveyId
            answers: $answers
            timeSpent: $timeSpent
          ) {
            ...PersistedLearnerWorkBasedActionFragment
          }
        }
      `),
      {
        id,
        publishedSurveyId: surveyId,
        answers,
        timeSpent,
      }
    )
    .then((data) => {
      if (data.submitWorkBasedActionLearnerSurveyWithTimeSpent) {
        return mapPersistedWBA(
          getFragmentData(
            PersistedLearnerWorkBasedActionFragment,
            data.submitWorkBasedActionLearnerSurveyWithTimeSpent
          )
        );
      } else {
        const error = new Error(
          'No learner work based action returned after submitting learner survey'
        );
        Sentry.captureException(error);
        throw error;
      }
    });
};

export const submitWorkBasedActionMentorSurvey = (
  client: GraphQLClient,
  id: string,
  surveyId: string,
  answers: SurveyQuestionUserAnswer[]
): Promise<PersistedLearnerWorkBasedActionWithUser> => {
  return client
    .request(
      graphql(`
        mutation SubmitWorkBasedActionMentorSurvey(
          $id: ID!
          $publishedSurveyId: ID!
          $answers: [SurveyQuestionUserAnswer!]!
        ) {
          submitWorkBasedActionMentorSurvey(
            id: $id
            publishedSurveyId: $publishedSurveyId
            answers: $answers
          ) {
            ...PersistedLearnerWorkBasedActionFragment
          }
        }
      `),
      {
        id,
        publishedSurveyId: surveyId,
        answers,
      }
    )
    .then((data) => {
      if (data.submitWorkBasedActionMentorSurvey) {
        return mapPersistedWBA(
          getFragmentData(
            PersistedLearnerWorkBasedActionFragment,
            data.submitWorkBasedActionMentorSurvey
          )
        );
      } else {
        const error = new Error(
          'No learner work based action returned after submitting mentor survey'
        );
        Sentry.captureException(error);
        throw error;
      }
    });
};

export const likeWorkBasedActionDiscussionItem = (
  client: GraphQLClient,
  courseId: string,
  actionId: string,
  discussionItemId: string
) => {
  const variables: MutationLikeWorkBasedActionDiscussionItemArgs = {
    courseId,
    actionId,
    discussionItemId,
  };
  return client.request(
    graphql(`
      mutation LikeWorkBasedActionDiscussionItem(
        $courseId: ID!
        $actionId: ID!
        $discussionItemId: ID!
      ) {
        likeWorkBasedActionDiscussionItem(
          courseId: $courseId
          actionId: $actionId
          discussionItemId: $discussionItemId
        )
      }
    `),
    variables
  );
};

export const unlikeWorkBasedActionDiscussionItem = (
  client: GraphQLClient,
  courseId: string,
  actionId: string,
  discussionItemId: string
) => {
  const variables: MutationLikeWorkBasedActionDiscussionItemArgs = {
    courseId,
    actionId,
    discussionItemId,
  };
  return client.request(
    graphql(`
      mutation UnlikeWorkBasedActionDiscussionItem(
        $courseId: ID!
        $actionId: ID!
        $discussionItemId: ID!
      ) {
        unlikeWorkBasedActionDiscussionItem(
          courseId: $courseId
          actionId: $actionId
          discussionItemId: $discussionItemId
        )
      }
    `),
    variables
  );
};

export const createWorkBasedActionDiscussionItem = (
  client: GraphQLClient,
  courseId: string,
  actionId: string,
  comment: string,
  parentDiscussionItemId?: string
) => {
  const variables: MutationAddWorkBasedActionDiscussionItemArgs = {
    courseId,
    actionId,
    comment,
    parentDiscussionItemId,
  };
  return client.request(
    graphql(`
      mutation AddWorkBasedActionDiscussionItem(
        $courseId: ID!
        $actionId: ID!
        $comment: String!
        $parentDiscussionItemId: ID
      ) {
        addWorkBasedActionDiscussionItem(
          courseId: $courseId
          actionId: $actionId
          comment: $comment
          parentDiscussionItemId: $parentDiscussionItemId
        ) {
          id
        }
      }
    `),
    variables
  );
};
// CLEANUP - anies
export const mapDiscussionItem = (
  item: any,
  activityId: string,
  stepId: string
): DiscussionItem => {
  return {
    id: item.id,
    text: item.text,
    nrOfLikes: item.nrOfLikes,
    liked: item.liked,
    secondsSinceLastUpdate: item.secondsSinceLastUpdate,
    activityId: activityId,
    stepId: stepId,
    createdBy: {
      id: item.createdBy.id,
      name: item.createdBy.name,
      thumbUri: item.createdBy.thumbUri,
    },
    createdAt: new Date(item.createdAt),
    replies: item.replies
      ? item.replies.map((reply: any) => mapDiscussionItem(reply, activityId, stepId))
      : [],
  };
};

export const mapPublishedSurvey = (survey: any): PublishedSurvey => {
  return {
    id: survey.id,
    title: survey.title,
    submitted: survey.submitted,
    questions: survey.questions?.map((q: any) => {
      return {
        ...q,
        ...{
          type: q.type.toLowerCase(),
          text: sanitizeHtml(q.text),
          answers: q.answers.map((a: any) => {
            return {
              ...a,
              label: a.text,
            };
          }),
        },
      };
    }),
  } as PublishedSurvey;
};
