import { useCallback, useEffect } from 'react';
import { NavigateOptions, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useCourseTracking from './hooks/use-course-tracking';
import {
  DiscussionItem,
  LearningActivityStep,
  LearningActivityStepSourceType,
  SaveCourseEventFunc,
  WorkBasedActionLearningActivityStep,
} from '@stellar-lms-frontend/lms-api';
import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { checkResStatus, navigation } from '@stellar-lms-frontend/common-utils';
import { LEARNING_ACTIVITY_STEP_ROUTE, STEP_URI_CREATE_CONST } from './routes';
import { LEARNING_ACTIVITY_STEP } from './query';
import { CourseOverviewNoAccessIcon, EmptyState } from '@stellar-lms-frontend/ui-components';
import { Assessment, IFrame, Survey, WBA, Block, Scenario } from '..';
import { IFrameActions } from '../iframe-step-view';
import { SurveyActions } from '../survey/survey-step';
import { AssessmentActions } from '../assessment';
import { WorkBasedActionActions } from '../wba/work-based-action-step';
import { BlockActions } from '../block-step';
import { useCourseData } from './hooks/use-course-data';
import { CurrentCompany, Course, GetLightCourseInfoQuery } from '@stellar-lms-frontend/lms-graphql';
import { ScenarioActions } from '../scenario';

const NAVIGATION_KEY = 'learning-activity';
export type LAStepViewActions = IFrameActions &
  SurveyActions &
  AssessmentActions &
  WorkBasedActionActions &
  ScenarioActions &
  BlockActions & {
    course: {
      getCourseOutline: (input: { courseId: string }) => Promise<Course>;
    };
    tracking: {
      saveCourseEvent?: SaveCourseEventFunc;
    };
    step: {
      getLearningActivityStep: (
        learningActivityId: string,
        stepId: string,
      ) => Promise<LearningActivityStep>;
    };
    comments?: {
      createComment?: (
        learningActivityId: string,
        stepId: string,
        comment: string,
      ) => Promise<void>;
      getDiscussionItems?: (
        learningActivityId: string,
        stepId: string,
        pageParam: number,
      ) => Promise<DiscussionItem[]>;
    };
  };

export type I18N = {
  error403Title: string;
  error403Description: string;
  error403ButtonCta: string;
};

export type LearningActivityStepViewProps = {
  i18n: I18N;
  discussionAllowed: boolean;
  isEditing?: boolean;
  currentCourse?: GetLightCourseInfoQuery['course'];
  currentCompany: CurrentCompany;
  actions: LAStepViewActions;
  documentsEnabled?: boolean;
};
export const LearningActivityStepView: React.FC<LearningActivityStepViewProps> = ({
  currentCompany,
  i18n,
  discussionAllowed = true,
  isEditing,
  currentCourse,
  actions,
  documentsEnabled,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'learning-activity-overview' });
  const { t: tAssessmentStepView } = useTranslation('translation', {
    keyPrefix: 'assessment-step-view',
  });
  const { t: tWBAStepView } = useTranslation('translation', { keyPrefix: 'work-based-actions' });
  const { t: tBlockStepView } = useTranslation('translation', { keyPrefix: 'block-step-view' });
  const { t: tScenarioStepView } = useTranslation('translation', {
    keyPrefix: 'scenario-step-view',
  });

  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });
  const { t: tValidation } = useTranslation('translation', { keyPrefix: 'validation' });

  const { courseId, learningActivityId, stepId } = useParams(); // TODO: expectation that there will be a courseId and LearningActivityId under that name in the URL, maybe they should be passed as props
  const location = useLocation();

  const {
    learningActivity,
    previousLearningStep,
    nextLearningStep,
    totalStepCount,
    currentStepNumber,
    moduleId,
  } = useCourseData(actions.course.getCourseOutline);
  const navigate = useNavigate();

  useCourseTracking(actions.tracking.saveCourseEvent, courseId, learningActivityId, stepId);
  const isStepQueryEnabled = !!learningActivityId && !!stepId && stepId !== STEP_URI_CREATE_CONST;

  const { data: step, error: stepRequestError } = useQuery<LearningActivityStep | null, AxiosError>(
    [LEARNING_ACTIVITY_STEP, stepId],
    () =>
      isStepQueryEnabled ? actions.step.getLearningActivityStep(learningActivityId, stepId) : null,
    {
      enabled: isStepQueryEnabled,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    const navigateToCourseOverview = () => navigate('..', { replace: true });

    const navigateToLearningActivityStep = (stepId: string, options?: NavigateOptions) => {
      const target = LEARNING_ACTIVITY_STEP_ROUTE(stepId);
      navigate(target, options);
    };

    const navigateToPreviousStep = () => {
      if (!learningActivity || !stepId) {
        return;
      }

      if (previousLearningStep) {
        navigateToLearningActivityStep(previousLearningStep.id, {
          replace: false,
          state: { navigationDirection: 'backwards' },
        });
      }
    };

    const navigateToNextStep = () => {
      if (!learningActivity || !stepId) {
        return;
      }

      if (nextLearningStep) {
        navigateToLearningActivityStep(nextLearningStep.id);
      } else {
        navigateToCourseOverview();
      }
    };

    navigation.setRightPrimary(NAVIGATION_KEY, {
      label: currentStepNumber !== totalStepCount ? t('next') : t('finish'),
      action: navigateToNextStep,
    });

    if (currentStepNumber > 1) {
      navigation.setRightSecondary(NAVIGATION_KEY, {
        label: t('previous'),
        action: navigateToPreviousStep,
      });
    } else {
      navigation.popRightSecondary(NAVIGATION_KEY);
    }

    navigation.setTitle(
      NAVIGATION_KEY,
      stepId === undefined
        ? t('navigation.title')
        : `${currentStepNumber} ${t('of')} ${totalStepCount}`,
    );
  }, [
    stepId,
    currentStepNumber,
    learningActivity,
    navigate,
    nextLearningStep,
    totalStepCount,
    t,
    previousLearningStep,
  ]);

  const renderStep = useCallback(
    (step?: LearningActivityStep) => {
      const stepType = step?.sourceType || location?.state?.stepType;
      switch (stepType) {
        case LearningActivityStepSourceType.BLOCK:
          return (
            <Block.BlockStep
              currentCompany={currentCompany}
              actions={actions}
              key={step?.id}
              step={step}
              currentCourse={currentCourse}
              discussionAllowed={discussionAllowed}
              isEditing={isEditing}
              moduleId={moduleId}
              documentsEnabled={documentsEnabled}
            />
          );
        case LearningActivityStepSourceType.IFRAME:
          return (
            <IFrame.IFrameStep
              currentCompany={currentCompany}
              actions={actions}
              key={step?.id}
              step={step}
              discussionAllowed={discussionAllowed}
              isEditing={isEditing}
            />
          );
        case LearningActivityStepSourceType.SURVEY:
          return (
            <Survey.SurveyStep
              currentCompany={currentCompany}
              key={step?.id}
              actions={actions}
              step={step}
              isEditing={isEditing}
              lastLearningActivity={totalStepCount === currentStepNumber}
            />
          );
        case LearningActivityStepSourceType.ASSESSMENT:
          return (
            <Assessment.AssessmentStep
              key={step?.id}
              currentCompany={currentCompany}
              actions={actions}
              step={step}
              isEditing={isEditing}
              moduleId={moduleId}
            />
          );
        case LearningActivityStepSourceType.WBA:
          return (
            <WBA.WorkBasedActionStep
              key={step?.id}
              currentCompany={currentCompany}
              step={step as WorkBasedActionLearningActivityStep}
              isLastStep={totalStepCount === currentStepNumber}
              isEditing={isEditing}
              actions={actions}
              moduleId={moduleId}
            />
          );
        case LearningActivityStepSourceType.SCENARIO:
          return (
            <Scenario.ScenarioStep
              currentCompany={currentCompany}
              key={step?.id}
              actions={actions}
              step={step}
              isEditing={isEditing}
              moduleId={moduleId}
            />
          );
        default:
          return null;
      }
    },
    [
      actions,
      currentCompany,
      currentStepNumber,
      discussionAllowed,
      isEditing,
      location?.state?.stepType,
      moduleId,
      tAssessmentStepView,
      tBlockStepView,
      tGeneral,
      tScenarioStepView,
      tValidation,
      tWBAStepView,
      totalStepCount,
      documentsEnabled,
    ],
  );

  if (checkResStatus(stepRequestError, 403)) {
    return (
      <EmptyState
        icon={<CourseOverviewNoAccessIcon />}
        title={i18n.error403Title}
        description={i18n.error403Description}
        actionText={i18n.error403ButtonCta}
        verticalCenter={true}
        onActionClick={() => {
          navigate('/');
        }}
      />
    );
  }

  return (
    <div
      data-test-id="learning-activity-step-detail"
      className="h-full w-full"
    >
      <div className="relative flex h-full w-full flex-row justify-between">
        {renderStep(step ?? undefined)}
      </div>
    </div>
  );
};

export default LearningActivityStepView;
