import { navigation, useScreenType } from '@stellar-lms-frontend/common-utils';
import {
  PrimaryButton,
  LinkButton,
  Heading4,
  ScrollContainer,
  Duration,
  DurationI18N,
  FloatingOptions,
  MoreVertIcon,
  CopyIcon,
  TrashIcon,
  Edit3Icon,
  AutoCompleteFuncRet,
} from '@stellar-lms-frontend/ui-components';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import {
  LEARNING_ACTIVITY_STEP_CREATE_URI,
  LEARNING_ACTIVITY_STEP_ROUTE,
  STEP_URI_CREATE_CONST,
} from './routes';
import {
  LearningActivityStepList,
  LearningActivityStepListActions,
  LearningActivityStepListI18N,
} from './components/learning-activity-step-list';
import {
  LearningActivityEditDrawer,
  LearningActivityEditDrawerI18N,
  LearningActivityEditFormData,
} from '../learning-activity-edit-drawer/learning-activity-edit-drawer';
import { LearningActivityContext } from './context/learning-activity-context';
import {
  NextLearningActivityButton,
  NextLearningActivityButtonI18N,
} from './components/next-learning-activity-button';
import { useCourseData } from './hooks/use-course-data';
import { LearningActivityType, getLearningActivityTypeIcon } from '../utils/learningActivity';
import {
  Course,
  GetLightCourseInfoQuery,
  RelatedStepSuggestionsQuery,
} from '@stellar-lms-frontend/lms-graphql';
import { RelatedStepsList } from './components/related-steps-list';

export type LearningActivityStepOverviewI18N = {
  laList?: LearningActivityStepListI18N;
  learningActivityEditDrawer?: LearningActivityEditDrawerI18N;
  duration: DurationI18N;
  nextLearningActivityButton: NextLearningActivityButtonI18N;
  edit?: string;
};

type LearningActivityPath = {
  courseId: string;
  learningActivityId: string;
  moduleId: string;
};

export type LearningActivityStepOverviewActions = {
  course: {
    getCourseOutline: (input: { courseId: string }) => Promise<Course>;
    fetchRelatedStepSuggestions: (
      courseId: string,
      moduleId: string,
      learningActivityId: string,
      nrPerPage: number,
    ) => Promise<RelatedStepSuggestionsQuery>;
    startRelatedStepSuggestionGeneration: (
      courseId: string,
      moduleId: string,
      learningActivityId: string,
    ) => Promise<boolean>;
    startRelatedContentStepGeneration: (
      courseId: string,
      moduleId: string,
      learningActivityId: string,
      suggestionId: string,
      suggestionTitle: string,
      suggestionDescription: string,
    ) => Promise<boolean>;
  };
  edit?: {
    onNavigateModule: (courseId: string, moduleId: string) => void;
  };
  learningActivity?: {
    duplicate: ({
      courseId,
      learningActivityId,
      moduleId,
    }: LearningActivityPath) => Promise<unknown>;
    delete: ({ courseId, learningActivityId, moduleId }: LearningActivityPath) => void;
    update: ({
      courseId,
      input,
      learningActivityId,
      moduleId,
    }: LearningActivityPath & {
      input: {
        description?: string;
        durationInSeconds: number;
        mandatoryPrerequisiteLearningActivityIds?: string[];
        openDate?: string;
        title: string;
        type: LearningActivityType;
      };
    }) => Promise<unknown>;
    generateHowTo: ({
      input,
    }: {
      input: {
        courseId: string;
        moduleId: string;
        learningActivityId: string;
      };
    }) => Promise<unknown>;
  };
  modal?: {
    openGEARModal: () => void;
  };
} & Omit<LearningActivityStepListActions, 'learningActivity'>;

export type LearningActivityStepOverviewProps = {
  discussionAllowed: boolean;
  i18n: LearningActivityStepOverviewI18N;
  setIsEditing?: Dispatch<SetStateAction<boolean>>;
  isEditing: boolean;
  currentCourse: GetLightCourseInfoQuery['course'];
  isCreating: boolean;
  actions: LearningActivityStepOverviewActions;
  autoCompletePrerequisites: (query: string) => AutoCompleteFuncRet;
};

export const LearningActivityStepOverview: React.FC<LearningActivityStepOverviewProps> = ({
  discussionAllowed,
  i18n,
  setIsEditing,
  isEditing,
  isCreating,
  currentCourse,
  actions,
  autoCompletePrerequisites,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'learning-activity-overview' });
  const { t: tGeneral } = useTranslation('translation', { keyPrefix: 'general' });
  const navigate = useNavigate();
  const { learningActivityId, stepId } = useParams();
  const { isTailwindLg } = useScreenType();
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [isLearningActivityEditDrawerOpen, setIsLearningActivityEditDrawerOpen] = useState(false);
  const { saveFunc, isDirty } = useContext(LearningActivityContext);

  useEffect(() => {
    if (!isEditing) {
      setIsLearningActivityEditDrawerOpen(false);
    }
  }, [isEditing]);

  const navigateToCourse = () => navigate('..');

  const navigateToLearningActivityStep = useCallback(
    (newStepId: string, replace = false) => {
      if (newStepId !== stepId) {
        const target = LEARNING_ACTIVITY_STEP_ROUTE(newStepId);
        navigate(target, { replace: replace });
      }
    },
    [navigate, stepId],
  );

  const courseId = currentCourse?.id;

  const {
    learningActivity,
    nextUncompletedLearningStep,
    doLoad,
    totalStepCount,
    nextLearningActivity,
    prevLearningActivity,
    moduleId,
  } = useCourseData(actions.course.getCourseOutline);

  const navigateToNextUncompletedStep = () => {
    if (nextUncompletedLearningStep) {
      navigateToLearningActivityStep(nextUncompletedLearningStep.id);
    } else if (learningActivity?.steps) {
      const firstStep = learningActivity.steps[0];
      if (firstStep) {
        navigateToLearningActivityStep(firstStep.id);
      }
    }
  };

  useEffect(() => {
    doLoad(stepId !== undefined);

    navigation.setTitle('step', t('navigation.title'));

    return () => navigation.clearLevel('step');
  }, [doLoad, stepId, t]);

  /*

  Removing this logic, for now the user will have to click on "Edit" also when there is no data yet in the module"
  useEffect(() => {
    if (learningActivity) {
      if (totalStepCount === 0 && stepId !== STEP_URI_CREATE_CONST) {
        // Handles empty case, when there is no steps
        setIsEditing?.(true);
        if (stepId === STEP_URI_CREATE_CONST) {
          navigate('./', { state: null });
        }
      }
    }
  }, [learningActivity, navigate, setIsEditing, stepId, totalStepCount]);*/

  useEffect(() => {
    if (learningActivity && learningActivity.steps) {
      if (isTailwindLg && stepId === undefined) {
        // Handle when there is no stepId on the URL, redirects the user to the proper step
        if (totalStepCount && totalStepCount > 0) {
          const previouslyVisited = learningActivity.steps.find((s) => s.visited);
          if (previouslyVisited) {
            navigateToLearningActivityStep(previouslyVisited.id, true);
          } else {
            navigateToLearningActivityStep(learningActivity.steps[0].id, true);
          }
        }
      } else if (
        // Handle wrong stepId, when the stepId on the url doesn't exist on learningActivity
        (stepId &&
          stepId !== STEP_URI_CREATE_CONST &&
          !learningActivity.steps.find((s) => s.id === stepId)) ||
        (stepId === STEP_URI_CREATE_CONST && !isEditing)
      ) {
        if (totalStepCount && totalStepCount > 0 && learningActivity.steps) {
          navigateToLearningActivityStep(learningActivity.steps[0].id, true);
        } else {
          navigate('./');
        }
      }
    }
  }, [
    isTailwindLg,
    isEditing,
    learningActivity,
    navigate,
    navigateToLearningActivityStep,
    stepId,
    totalStepCount,
  ]);

  const onSaveLearningActivity = useCallback(
    async (data: LearningActivityEditFormData) => {
      if (courseId && learningActivityId && learningActivity?.type && moduleId) {
        actions?.learningActivity?.update({
          courseId,
          learningActivityId,
          moduleId: moduleId,
          input: {
            ...data,
            type: learningActivity.type,
            mandatoryPrerequisiteLearningActivityIds:
              data.mandatoryPrerequisiteLearningActivityIds.map((p) => p.id),
          },
        });
        setIsLearningActivityEditDrawerOpen(false);
      }
    },
    [courseId, learningActivityId, moduleId, actions?.learningActivity, learningActivity?.type],
  );

  const onDeleteLearningActivity = useCallback(() => {
    if (courseId && learningActivityId && moduleId) {
      actions?.learningActivity?.delete({
        courseId,
        learningActivityId,
        moduleId: moduleId,
      });
      setIsLearningActivityEditDrawerOpen(false);
    }
  }, [actions?.learningActivity, courseId, moduleId, learningActivityId]);

  const onDuplicateLearningActivity = useCallback(() => {
    if (courseId && learningActivityId && moduleId) {
      actions?.learningActivity?.duplicate({
        courseId,
        learningActivityId,
        moduleId: moduleId,
      });
      setIsOptionsOpen(false);
      // Temporary solution: to redirect to the new duplicated LA we need BE work
      actions?.edit?.onNavigateModule(courseId, moduleId);
    }
  }, [actions?.edit, actions?.learningActivity, courseId, moduleId, learningActivityId]);

  const drawerLaNavigation = useMemo(
    () => ({
      onNavigatePrev: prevLearningActivity?.id
        ? () => navigate(`../learning-activity/${prevLearningActivity?.id}`)
        : undefined,
      onNavigateNext: nextLearningActivity?.id
        ? () => navigate(`../learning-activity/${nextLearningActivity?.id}`)
        : undefined,
    }),
    [navigate, nextLearningActivity?.id, prevLearningActivity?.id],
  );

  const Icon = getLearningActivityTypeIcon(learningActivity?.type ?? LearningActivityType.Unknown);

  if (!courseId || !learningActivityId || !moduleId) {
    return null;
  }

  return (
    <>
      <div
        className={`flex h-full w-full flex-col justify-between
      ${isEditing ? 'bg-surface-02' : ''}`}
      >
        <ScrollContainer
          scrollOnDesktop
          isFullHeight
        >
          <div className="flex h-full flex-col items-center">
            <div
              className="max-w-content flex h-full w-full flex-col justify-between overflow-auto p-10 pr-12"
              data-test-id="learning-activity-step-overview"
            >
              <div className="flex items-start gap-4">
                <Icon className="text-primary-02 h-10 w-10 shrink-0" />
                <div className="hidden grow space-y-2 lg:block">
                  <div className="flex items-center justify-between">
                    <Heading4 className={isEditing ? 'text-primary-01' : 'text-text-01'}>
                      {learningActivity ? learningActivity.title : <>&nbsp;</>}
                    </Heading4>
                    {setIsEditing && (
                      <div className="shrink-0">
                        {isEditing && (
                          <FloatingOptions
                            wrappedComponent={
                              <button onClick={() => setIsOptionsOpen((prev) => !prev)}>
                                <MoreVertIcon className="text-gray-700" />
                              </button>
                            }
                            isOpen={isOptionsOpen}
                            onClose={() => setIsOptionsOpen(false)}
                            options={[
                              {
                                label: tGeneral('edit-details'),
                                onClick: () => {
                                  setIsLearningActivityEditDrawerOpen((prev) => !prev);
                                  setIsOptionsOpen(false);
                                },
                                left: <Edit3Icon className="text-text-01 h-4 w-4" />,
                              },
                              {
                                label: tGeneral('duplicate'),
                                onClick: onDuplicateLearningActivity,
                                left: <CopyIcon className="text-text-01 h-4 w-4" />,
                              },
                              {
                                label: tGeneral('delete'),
                                onClick: onDeleteLearningActivity,
                                className: 'text-negative-01',
                                left: <TrashIcon className="text-negative-01 h-4 w-4" />,
                              },
                            ]}
                          />
                        )}
                      </div>
                    )}
                  </div>
                  <div className="text-text-02 text-sm font-medium">
                    <Duration
                      i18n={i18n?.duration}
                      className="text-primary-02 "
                      seconds={learningActivity?.duration ?? 0}
                    />
                    {learningActivity?.description ? ` - ${learningActivity?.description}` : ''}
                  </div>
                </div>
              </div>
              <div className="grow space-y-6">
                <LearningActivityStepList
                  learningActivity={learningActivity}
                  navigateToLearningActivityStep={navigateToLearningActivityStep}
                  highlightedStepId={stepId}
                  discussionAllowed={discussionAllowed}
                  isEditing={isEditing}
                  isCreating={isCreating}
                  i18n={i18n?.laList}
                  actions={{
                    ...actions,
                    learningActivity: {
                      generateHowTo: actions.learningActivity
                        ? async () => {
                            await actions.learningActivity?.generateHowTo({
                              input: { courseId, moduleId, learningActivityId },
                            });
                          }
                        : () => Promise.resolve(),
                    },
                  }}
                  moduleId={moduleId}
                />
                {isEditing && (
                  <RelatedStepsList
                    courseId={courseId}
                    moduleId={moduleId}
                    learningActivityId={learningActivityId}
                    courseRealtimeChannelId={currentCourse?.realtimeChannel?.id}
                    fetchRelatedSteps={actions.course.fetchRelatedStepSuggestions}
                    startRelatedStepSuggestionGeneration={
                      actions.course.startRelatedStepSuggestionGeneration
                    }
                    startRelatedContentStepGeneration={
                      actions.course.startRelatedContentStepGeneration
                    }
                  />
                )}
              </div>
              <NextLearningActivityButton
                className="mt-6"
                i18n={i18n.nextLearningActivityButton}
                learningActivity={nextLearningActivity ?? undefined}
              />
            </div>
          </div>
        </ScrollContainer>
        {!isTailwindLg && (
          <div className="flex flex-col items-center">
            <div className="max-w-content w-full space-y-2 px-8 xl:p-16">
              <PrimaryButton
                htmlId="cta_start-learning"
                label={nextUncompletedLearningStep ? t('continue-learning') : t('start-learning')}
                onClick={() => navigateToNextUncompletedStep()}
                fullWidth={true}
              />
              <LinkButton
                htmlId="cta_back-to-course"
                label={t('back-to-course')}
                onClick={() => navigateToCourse()}
                fullWidth={true}
              />
            </div>
          </div>
        )}
      </div>
      {i18n?.learningActivityEditDrawer && actions?.modal?.openGEARModal && (
        <LearningActivityEditDrawer
          key={learningActivity?.id}
          isPlaceholder={learningActivity?.placeholder ?? false}
          isOpen={isLearningActivityEditDrawerOpen}
          onClose={() => setIsLearningActivityEditDrawerOpen(false)}
          onSave={onSaveLearningActivity}
          onDelete={onDeleteLearningActivity}
          onDuplicate={onDuplicateLearningActivity}
          i18n={i18n?.learningActivityEditDrawer}
          prerequisiteAutocompleteFunc={autoCompletePrerequisites}
          data={{
            title: learningActivity?.title ?? '',
            openDate: learningActivity?.dateOpen?.toString() ?? '',
            description: learningActivity?.description ?? '',
            durationInSeconds: learningActivity?.duration ?? 0,
            mandatoryPrerequisiteLearningActivityIds:
              learningActivity?.prerequisites?.map((p) => {
                return { id: p?.module?.id ?? '', label: p.module?.title ?? '' };
              }) ?? [],
          }}
          laNavigation={drawerLaNavigation}
          steps={
            learningActivity?.steps?.map((s) => ({
              id: s.id ?? '',
              title: s.title ?? '',
            })) ?? []
          }
          onStepClick={navigateToLearningActivityStep}
          addStep={{
            isEnabled: true,
            onClick: (callback) =>
              isDirty ? actions?.step?.onCreate?.(callback, saveFunc) : callback(),
            url: LEARNING_ACTIVITY_STEP_CREATE_URI,
          }}
          type={learningActivity?.type ?? LearningActivityType.Unknown}
          onOpenGEARModal={actions?.modal.openGEARModal}
        />
      )}
    </>
  );
};

export default LearningActivityStepOverview;
