import {
  AnalyseArticleUrlForEditorQueryVariables,
  ArticleSearchTermGenerationMutationVariables,
  ContentEntitiesGenerationMutationVariables,
  CreateBlockContentItemMutationVariables,
  CreateBlockContentStepMutationVariables,
  CreateScenarioStepMutationVariables,
  DesignerAssessmentStepQueryVariables,
  DesignerSurveyStepQueryVariables,
  GetDefaultHeaderImageQueryVariables,
  graphql,
  MarkSuggestedActionAsAcceptedMutationVariables,
  MarkSuggestedQuestionAsAcceptedMutationVariables,
  MutationContentGenerationArgs,
  MutationCreateContentStepArgs,
  MutationCreateQuizStepArgs,
  MutationCreateSurveyStepArgs,
  MutationCreateWbaStepArgs,
  MutationDeleteStepArgs,
  MutationHowToGenerationArgs,
  MutationRelatedStepsGenerationArgs,
  MutationScenarioGenerationArgs,
  MutationUpdateContentStepArgs,
  MutationUpdateQuizStepArgs,
  MutationUpdateSurveyStepArgs,
  MutationUpdateWbaStepArgs,
  PresignedUrl,
  QueryGetFileUrlArgs,
  QueryGetUploadUrlArgs,
  QueryLearningActivityStepArgs,
  QuerySuggestedRelatedContentArgs,
  QuerySuggestedScenariosArgs,
  QuestionGenerationMutationVariables,
  ScenarioStepQueryVariables,
  SearchArticlesQueryVariables,
  SearchVideosQueryVariables,
  SuggestedActionsQueryVariables,
  SuggestedArticleSearchTermQueryVariables,
  SuggestedContentEntitiesQueryVariables,
  SuggestedImagesQueryVariables,
  SuggestedQuestionsQueryVariables,
  SuggestedVideoSearchTermQueryVariables,
  UpdateBlockContentItemMutationVariables,
  UpdateBlockContentStepMutationVariables,
  UpdateLearningActivityStepsOrderMutationVariables,
  UpdateScenarioStepMutationVariables,
  VideoSearchTermGenerationMutationVariables,
  WorkBasedActionGenerationMutationVariables,
} from '@stellar-lms-frontend/lms-graphql';
import {
  defaultGraphqlClient,
  objectApply,
  sanitizeHtml,
} from '@stellar-lms-frontend/common-utils';
import axios from 'axios';
import { graphQLClient } from '../../../lib/graphql';

export const deleteStep = (variables: MutationDeleteStepArgs) => {
  return graphQLClient('designer').request(
    graphql(`
      mutation designerDeleteLearningActivityStep($learningActivityId: ID!, $stepId: ID!) {
        deleteStep(learningActivityId: $learningActivityId, stepId: $stepId)
      }
    `),
    variables,
  );
};

export const generateHowTo = (variables: MutationHowToGenerationArgs) => {
  return graphQLClient('designer').request(
    graphql(`
      mutation HowToGeneration($input: HowToGenerationInput!) {
        howToGeneration(input: $input)
      }
    `),
    variables,
  );
};

export const createContentStep = (variables: MutationCreateContentStepArgs) => {
  return graphQLClient('designer').request(
    graphql(`
      mutation CreateContentStep($learningActivityId: ID!, $input: ContentStepInput!) {
        createContentStep(learningActivityId: $learningActivityId, input: $input) {
          id
        }
      }
    `),
    variables,
  );
};

export const updateContentStep = (variables: MutationUpdateContentStepArgs) => {
  return graphQLClient('designer').request(
    graphql(`
      mutation UpdateContentStep(
        $learningActivityId: ID!
        $stepId: ID!
        $input: ContentStepInput!
      ) {
        updateContentStep(learningActivityId: $learningActivityId, stepId: $stepId, input: $input) {
          id
        }
      }
    `),
    variables,
  );
};

export const createSurveyStep = (variables: MutationCreateSurveyStepArgs) => {
  return graphQLClient('designer').request(
    graphql(`
      mutation CreateSurveyStep($learningActivityId: ID!, $input: SurveyStepInput!) {
        createSurveyStep(learningActivityId: $learningActivityId, input: $input) {
          id
        }
      }
    `),
    variables,
  );
};

export const updateSurveyStep = (variables: MutationUpdateSurveyStepArgs) => {
  return graphQLClient('designer').request(
    graphql(`
      mutation UpdateSurveyStep($learningActivityId: ID!, $stepId: ID!, $input: SurveyStepInput!) {
        updateSurveyStep(learningActivityId: $learningActivityId, stepId: $stepId, input: $input) {
          id
        }
      }
    `),
    variables,
  );
};

export const createAssessmentStep = (variables: MutationCreateQuizStepArgs) => {
  return graphQLClient('designer').request(
    graphql(`
      mutation CreateQuizStep($learningActivityId: ID!, $input: QuizStepInput!) {
        createQuizStep(learningActivityId: $learningActivityId, input: $input) {
          id
        }
      }
    `),
    variables,
  );
};

export const updateAssessmentStep = (variables: MutationUpdateQuizStepArgs) => {
  return graphQLClient('designer').request(
    graphql(`
      mutation UpdateQuizStep($learningActivityId: ID!, $stepId: ID!, $input: QuizStepInput!) {
        updateQuizStep(learningActivityId: $learningActivityId, stepId: $stepId, input: $input) {
          id
        }
      }
    `),
    variables,
  );
};

export const createWBAStep = (variables: MutationCreateWbaStepArgs) => {
  return graphQLClient('designer').request(
    graphql(`
      mutation CreateWBAStep($learningActivityId: ID!, $input: WBAStepInput!) {
        createWBAStep(learningActivityId: $learningActivityId, input: $input) {
          id
          title
          description
          learnerShortDescription
          learnerDescription
        }
      }
    `),
    variables,
  );
};

export const updateWBAStep = (variables: MutationUpdateWbaStepArgs) => {
  return graphQLClient('designer').request(
    graphql(`
      mutation UpdateWBAStep($learningActivityId: ID!, $stepId: ID!, $input: WBAStepInput!) {
        updateWBAStep(learningActivityId: $learningActivityId, stepId: $stepId, input: $input) {
          id
          sourceId
          title
          description
          learnerShortDescription
          learnerDescription
        }
      }
    `),
    variables,
  );
};

export const fetchBlockContentStep = (variables: QueryLearningActivityStepArgs) => {
  return graphQLClient('designer').request(
    graphql(`
      query DesignerBlockContentStep($learningActivityId: ID!, $stepId: ID!) {
        learningActivityStep(learningActivityId: $learningActivityId, stepId: $stepId) {
          id
          title
          headerImage {
            url
            fileId
            isFullWidth
          }
          ... on BlockContentStep {
            __typename
            blocks {
              content
              id
              type
              editorVersion
            }
          }
        }
      }
    `),
    variables,
  );
};

export const fetchWBAStep = (variables: QueryLearningActivityStepArgs) => {
  return graphQLClient('designer').request(
    graphql(`
      query QueryWBAStep($learningActivityId: ID!, $stepId: ID!) {
        learningActivityStep(learningActivityId: $learningActivityId, stepId: $stepId) {
          id
          sourceId
          title
          headerImage {
            url
            fileId
            isFullWidth
          }
          ... on WBAStep {
            __typename
            description
            learnerDescription
            learnerShortDescription
            learnerSurvey {
              questions {
                id
                text
                required
                visibleToMentors
                ... on SurveyChoiceQuestion {
                  __typename
                  multiple
                  answers {
                    id
                    text
                  }
                }
                ... on SurveyFreeTextQuestion {
                  __typename
                  multiline
                }
                ... on SurveyScaleQuestion {
                  __typename
                  rightLabel
                  leftLabel
                  answers {
                    id
                    text
                  }
                }
              }
            }
            mentorSurvey {
              questions {
                id
                text
                required
                visibleToMentors
                ... on SurveyChoiceQuestion {
                  __typename
                  multiple
                  answers {
                    id
                    text
                  }
                }
                ... on SurveyFreeTextQuestion {
                  __typename
                  multiline
                }
                ... on SurveyScaleQuestion {
                  __typename
                  answers {
                    id
                    text
                  }
                  leftLabel
                  rightLabel
                }
              }
            }
          }
        }
      }
    `),
    variables,
  );
};

export const fetchSurveyStep = (variables: DesignerSurveyStepQueryVariables) => {
  return graphQLClient('designer')
    .request(
      graphql(`
        query DesignerSurveyStep($learningActivityId: ID!, $stepId: ID!) {
          learningActivityStep(learningActivityId: $learningActivityId, stepId: $stepId) {
            ... on SurveyStep {
              __typename
              id
              title
              headerImage {
                url
                fileId
                isFullWidth
              }
              questions {
                __typename
                id
                text
                required
                visibleToMentors
                ... on SurveyChoiceQuestion {
                  multiple
                  answers {
                    id
                    text
                  }
                }
                ... on SurveyFreeTextQuestion {
                  multiline
                }
                ... on SurveyScaleQuestion {
                  leftLabel
                  rightLabel
                  answers {
                    id
                    text
                  }
                }
              }
            }
          }
        }
      `),
      variables,
    )
    .then((res) => objectApply(res, (v: any) => (typeof v === 'string' ? sanitizeHtml(v) : v)));
};

export const fetchDefaultHeaderImage = (variables: GetDefaultHeaderImageQueryVariables) => {
  return defaultGraphqlClient
    .request(
      graphql(`
        query getDefaultHeaderImage($type: LearningActivityStepSourceType!, $courseId: String!) {
          getDefaultHeaderImage(type: $type, courseId: $courseId) {
            fileId
            url
            isFullWidth
          }
        }
      `),
      variables,
    )
    .then((res) => res.getDefaultHeaderImage);
};

export const fetchAssessmentStep = (variables: DesignerAssessmentStepQueryVariables) => {
  return graphQLClient('designer')
    .request(
      graphql(`
        query DesignerAssessmentStep($learningActivityId: ID!, $stepId: ID!) {
          learningActivityStep(learningActivityId: $learningActivityId, stepId: $stepId) {
            id
            title
            headerImage {
              url
              fileId
              isFullWidth
            }
            ... on QuizStep {
              __typename
              id
              questions {
                ... on QuizChoiceQuestion {
                  __typename
                  multiple
                  answers {
                    id
                    text
                    correct
                  }
                }
                ... on QuizFillInTheBlanksQuestion {
                  __typename
                  answers {
                    id
                    text
                    label
                  }
                }
                text
                spacedRepetitionEnabled
                id
                feedback
              }
              title
            }
          }
        }
      `),
      variables,
    )
    .then((res) => objectApply(res, (v: any) => (typeof v === 'string' ? sanitizeHtml(v) : v)));
};

export const updateLearningActivityStepsOrder = (
  variables: UpdateLearningActivityStepsOrderMutationVariables,
) => {
  return graphQLClient('designer').request(
    graphql(`
      mutation UpdateLearningActivityStepsOrder(
        $courseId: ID!
        $moduleId: ID!
        $learningActivityId: ID!
        $stepIds: [String!]!
      ) {
        updateLearningActivityStepsOrder(
          courseId: $courseId
          moduleId: $moduleId
          learningActivityId: $learningActivityId
          stepIds: $stepIds
        ) {
          id
        }
      }
    `),
    variables,
  );
};

export const fetchSuggestedQuestions = (variables: SuggestedQuestionsQueryVariables) => {
  return graphQLClient('designer')
    .request(
      graphql(`
        query SuggestedQuestions(
          $courseId: ID!
          $moduleId: ID!
          $nrPerPage: Int!
          $cursor: String
        ) {
          suggestedQuestions(
            courseId: $courseId
            moduleId: $moduleId
            nrPerPage: $nrPerPage
            cursor: $cursor
          ) {
            status
            pageInfo {
              cursor
              hasNextPage
            }
            suggestions {
              id
              text
              feedback
              ... on ChoiceSuggestedQuestion {
                __typename
                answers {
                  correct
                  id
                  text
                }
              }
              ... on FillInTheBlanksSuggestedQuestion {
                options
                __typename
                options
              }
            }
          }
        }
      `),
      variables,
    )
    .then((res) => res.suggestedQuestions);
};

export const markSuggestedQuestionAsAccepted = (
  variables: MarkSuggestedQuestionAsAcceptedMutationVariables,
) => {
  return graphQLClient('designer').request(
    graphql(`
      mutation MarkSuggestedQuestionAsAccepted($courseId: ID!, $suggestedQuestionId: ID!) {
        markSuggestedQuestionAsAccepted(
          courseId: $courseId
          suggestedQuestionId: $suggestedQuestionId
        )
      }
    `),
    variables,
  );
};

export const createBlockContentStep = (variables: CreateBlockContentStepMutationVariables) => {
  return graphQLClient('designer').request(
    graphql(`
      mutation CreateBlockContentStep($learningActivityId: ID!, $input: BlockContentStepInput!) {
        createBlockContentStep(learningActivityId: $learningActivityId, input: $input) {
          id
        }
      }
    `),
    variables,
  );
};

export const createBlockContentItem = (variables: CreateBlockContentItemMutationVariables) => {
  return graphQLClient('designer').request(
    graphql(`
      mutation CreateBlockContentItem(
        $learningActivityId: ID!
        $stepId: ID!
        $input: BlockContentItemInput!
      ) {
        createBlockContentItem(
          learningActivityId: $learningActivityId
          stepId: $stepId
          input: $input
        ) {
          id
        }
      }
    `),
    variables,
  );
};

export const updateBlockContentItem = (variables: UpdateBlockContentItemMutationVariables) => {
  return graphQLClient('designer').request(
    graphql(`
      mutation UpdateBlockContentItem(
        $learningActivityId: ID!
        $blockId: ID!
        $input: BlockContentItemInput!
        $stepId: ID!
      ) {
        updateBlockContentItem(
          learningActivityId: $learningActivityId
          blockId: $blockId
          input: $input
          stepId: $stepId
        ) {
          id
        }
      }
    `),
    variables,
  );
};

export const updateBlockContentStep = (variables: UpdateBlockContentStepMutationVariables) => {
  return graphQLClient('designer').request(
    graphql(`
      mutation UpdateBlockContentStep(
        $learningActivityId: ID!
        $input: BlockContentStepInput!
        $stepId: ID!
      ) {
        updateBlockContentStep(
          learningActivityId: $learningActivityId
          input: $input
          stepId: $stepId
        ) {
          id
        }
      }
    `),
    variables,
  );
};

export const getUploadUrl = (variables: QueryGetUploadUrlArgs) => {
  return graphQLClient('designer')
    .request(
      graphql(`
        query UrlForContentUpload($companyId: ID!, $fileName: String!, $mimeType: String!) {
          getUploadUrl(companyId: $companyId, fileName: $fileName, mimeType: $mimeType) {
            fileId
            url
          }
        }
      `),
      variables,
    )
    .then((res) => res.getUploadUrl as PresignedUrl);
};

export const getFileUrl = (variables: QueryGetFileUrlArgs) => {
  return graphQLClient('designer')
    .request(
      graphql(`
        query UrlForUploadedContent($companyId: ID!, $fileId: String!) {
          getFileUrl(companyId: $companyId, fileId: $fileId) {
            fileId
            url
          }
        }
      `),
      variables,
    )
    .then((res) => res.getFileUrl as PresignedUrl);
};

export const uploadFile = (url: string, file: File, callback?: (progres: number) => void) => {
  return axios
    .put<void>(url, file, {
      headers: { 'Content-Type': file.type },
      onUploadProgress: (progressEvent) => {
        if (callback) {
          callback(Math.round((progressEvent.loaded * 100) / (progressEvent.total ?? 1)));
        }
      },
    })
    .then((res) => res.data);
};

export const questionGeneration = (variables: QuestionGenerationMutationVariables) => {
  return graphQLClient('designer')
    .request(
      graphql(`
        mutation QuestionGeneration($input: SuggestedQuestionGenerationInput!) {
          questionGeneration(input: $input)
        }
      `),
      variables,
    )
    .then((res) => !!res.questionGeneration);
};

export const fetchSuggestedContentEntities = (
  variables: SuggestedContentEntitiesQueryVariables,
) => {
  return graphQLClient('designer').request(
    graphql(`
      query SuggestedContentEntities(
        $courseId: ID!
        $moduleId: ID!
        $learningActivityStepId: ID!
        $nrPerPage: Int!
        $learningActivityId: ID!
        $cursor: String
      ) {
        suggestedContentEntities(
          courseId: $courseId
          moduleId: $moduleId
          learningActivityStepId: $learningActivityStepId
          nrPerPage: $nrPerPage
          learningActivityId: $learningActivityId
          cursor: $cursor
        ) {
          pageInfo {
            cursor
            hasNextPage
          }
          status
          suggestions {
            entity
            id
          }
        }
      }
    `),
    variables,
  );
};

export const startContentEntitiesGeneration = (
  variables: ContentEntitiesGenerationMutationVariables,
) => {
  return graphQLClient('designer')
    .request(
      graphql(`
        mutation ContentEntitiesGeneration($input: SuggestedContentEntitiesGenerationInput!) {
          contentEntitiesGeneration(input: $input)
        }
      `),
      variables,
    )
    .then((res) => !!res.contentEntitiesGeneration);
};

export const fetchSuggestedImages = (variables: SuggestedImagesQueryVariables) => {
  return graphQLClient('designer').request(
    graphql(`
      query SuggestedImages(
        $courseId: ID!
        $tags: [String!]!
        $nrPerPage: Int!
        $cursor: String
        $language: InputLanguage
      ) {
        suggestedImages(
          courseId: $courseId
          tags: $tags
          nrPerPage: $nrPerPage
          cursor: $cursor
          language: $language
        ) {
          pageInfo {
            cursor
            hasNextPage
          }
          suggestions {
            creator {
              id
              name
              url
            }
            description
            fullUrl
            id
            thumbnailUrl
            provider {
              name
              url
            }
          }
        }
      }
    `),
    variables,
  );
};

export const searchStepArticles = (variables: SearchArticlesQueryVariables) => {
  return defaultGraphqlClient
    .request(
      graphql(`
        query SearchArticles($courseId: ID!, $search: String!) {
          searchArticles(courseId: $courseId, search: $search) {
            articles {
              title
              description
              source
              url
              iconUrl
            }
          }
        }
      `),
      variables,
    )
    .then((res) => res.searchArticles.articles ?? undefined);
};
export const startArticleSearchTermGeneration = (
  variables: ArticleSearchTermGenerationMutationVariables,
) => {
  return defaultGraphqlClient
    .request(
      graphql(`
        mutation ArticleSearchTermGeneration($input: SuggestedArticleSearchTermGenerationInput!) {
          articleSearchTermGeneration(input: $input)
        }
      `),
      variables,
    )
    .then((res) => !!res.articleSearchTermGeneration);
};
export const getSuggestedArticleSearchTerm = (
  variables: SuggestedArticleSearchTermQueryVariables,
) => {
  return defaultGraphqlClient
    .request(
      graphql(`
        query SuggestedArticleSearchTerm(
          $courseId: ID!
          $moduleId: ID!
          $learningActivityId: ID!
          $learningActivityStepId: ID!
        ) {
          suggestedArticleSearchTerm(
            courseId: $courseId
            moduleId: $moduleId
            learningActivityId: $learningActivityId
            learningActivityStepId: $learningActivityStepId
          ) {
            status
            suggestions {
              term
            }
          }
        }
      `),
      variables,
    )
    .then((res) => res.suggestedArticleSearchTerm);
};

export const searchStepVideos = (variables: SearchVideosQueryVariables) => {
  return defaultGraphqlClient
    .request(
      graphql(`
        query SearchVideos($courseId: ID!, $search: String!) {
          searchVideos(courseId: $courseId, search: $search) {
            videos {
              title
              description
              url
              embedUrl
              service
            }
          }
        }
      `),
      variables,
    )
    .then((res) => res.searchVideos.videos ?? undefined);
};
export const startVideoSearchTermGeneration = (
  variables: VideoSearchTermGenerationMutationVariables,
) => {
  return defaultGraphqlClient
    .request(
      graphql(`
        mutation VideoSearchTermGeneration($input: SuggestedVideoSearchTermGenerationInput!) {
          videoSearchTermGeneration(input: $input)
        }
      `),
      variables,
    )
    .then((res) => !!res.videoSearchTermGeneration);
};

export const getSuggestedVideoSearchTerm = (variables: SuggestedVideoSearchTermQueryVariables) => {
  return defaultGraphqlClient
    .request(
      graphql(`
        query SuggestedVideoSearchTerm(
          $courseId: ID!
          $moduleId: ID!
          $learningActivityId: ID!
          $learningActivityStepId: ID!
        ) {
          suggestedVideoSearchTerm(
            courseId: $courseId
            moduleId: $moduleId
            learningActivityId: $learningActivityId
            learningActivityStepId: $learningActivityStepId
          ) {
            status
            suggestions {
              term
            }
          }
        }
      `),
      variables,
    )
    .then((res) => res.suggestedVideoSearchTerm);
};

export const analyseArticleUrlForEditor = (variables: AnalyseArticleUrlForEditorQueryVariables) => {
  return defaultGraphqlClient
    .request(
      graphql(`
        query AnalyseArticleUrlForEditor($url: String!, $courseId: String!) {
          analyseArticleUrlForEditor(url: $url, courseId: $courseId) {
            link
            title
            description
            source
            iconUrl
          }
        }
      `),
      variables,
    )
    .then((res) => res.analyseArticleUrlForEditor ?? undefined);
};

export const actionGeneration = (variables: WorkBasedActionGenerationMutationVariables) => {
  return graphQLClient('designer')
    .request(
      graphql(`
        mutation WorkBasedActionGeneration($input: SuggestedWorkBasedActionGenerationInput!) {
          workBasedActionGeneration(input: $input)
        }
      `),
      variables,
    )
    .then((res) => !!res.workBasedActionGeneration);
};

export const getActionsSuggestions = (variables: SuggestedActionsQueryVariables) => {
  return graphQLClient('designer')
    .request(
      graphql(`
        query SuggestedActions($courseId: ID!, $moduleId: ID!, $nrPerPage: Int!, $cursor: String) {
          suggestedActions(
            courseId: $courseId
            moduleId: $moduleId
            nrPerPage: $nrPerPage
            cursor: $cursor
          ) {
            status
            suggestions {
              id
              longDescription
              shortDescription
              title
            }
            pageInfo {
              cursor
              hasNextPage
            }
          }
        }
      `),
      variables,
    )
    .then((res) => res.suggestedActions);
};

export const markSuggestedActionAsAccepted = (
  variables: MarkSuggestedActionAsAcceptedMutationVariables,
) => {
  return graphQLClient('designer').request(
    graphql(`
      mutation MarkSuggestedActionAsAccepted($courseId: ID!, $suggestedActionId: ID!) {
        markSuggestedActionAsAccepted(courseId: $courseId, suggestedActionId: $suggestedActionId)
      }
    `),
    variables,
  );
};

export const createScenarioStep = (variables: CreateScenarioStepMutationVariables) => {
  return graphQLClient('designer').request(
    graphql(`
      mutation CreateScenarioStep($learningActivityId: ID!, $input: ScenarioStepInput!) {
        createScenarioStep(learningActivityId: $learningActivityId, input: $input) {
          id
        }
      }
    `),
    variables,
  );
};

export const updateScenarioStep = (variables: UpdateScenarioStepMutationVariables) => {
  return graphQLClient('designer').request(
    graphql(`
      mutation UpdateScenarioStep(
        $learningActivityId: ID!
        $stepId: ID!
        $input: ScenarioStepInput!
      ) {
        updateScenarioStep(
          learningActivityId: $learningActivityId
          stepId: $stepId
          input: $input
        ) {
          id
        }
      }
    `),
    variables,
  );
};

export const fetchScenarioStep = (variables: ScenarioStepQueryVariables) => {
  return graphQLClient('designer')
    .request(
      graphql(`
        query DesignerScenarioStep($learningActivityId: ID!, $stepId: ID!) {
          learningActivityStep(learningActivityId: $learningActivityId, stepId: $stepId) {
            ... on ScenarioStep {
              __typename
              id
              title
              headerImage {
                url
                isFullWidth
                fileId
              }
              levels {
                id
                text
                multiple
                spacedRepetitionEnabled
                answers {
                  correct
                  feedback
                  id
                  text
                }
              }
            }
          }
        }
      `),
      variables,
    )
    .then((res) => objectApply(res, (v: any) => (typeof v === 'string' ? sanitizeHtml(v) : v)));
};

export const scenarioGeneration = (variables: MutationScenarioGenerationArgs) => {
  return graphQLClient('designer')
    .request(
      graphql(`
        mutation ScenarioGeneration($input: SuggestedScenarioGenerationInput!) {
          scenarioGeneration(input: $input)
        }
      `),
      variables,
    )
    .then((res) => !!res.scenarioGeneration);
};

export const relatedStepSuggestionGeneration = (variables: MutationRelatedStepsGenerationArgs) => {
  return defaultGraphqlClient
    .request(
      graphql(`
        mutation RelatedStepsGeneration($input: RelatedStepsGenerationInput!) {
          relatedStepsGeneration(input: $input)
        }
      `),
      variables,
    )
    .then((res) => !!res.relatedStepsGeneration);
};

export const fetchRelatedStepSuggestions = (variables: QuerySuggestedRelatedContentArgs) => {
  return graphQLClient('designer').request(
    graphql(`
      query RelatedStepSuggestions(
        $courseId: ID!
        $moduleId: ID!
        $learningActivityId: ID!
        $nrPerPage: Int!
      ) {
        suggestedRelatedContent(
          courseId: $courseId
          moduleId: $moduleId
          learningActivityId: $learningActivityId
          nrPerPage: $nrPerPage
        ) {
          status
          suggestions {
            description
            id
            title
          }
        }
      }
    `),
    variables,
  );
};

export const relatedContentGeneration = (variables: MutationContentGenerationArgs) => {
  return graphQLClient('designer')
    .request(
      graphql(`
        mutation ContentGeneration($input: ContentGenerationInput!) {
          contentGeneration(input: $input)
        }
      `),
      variables,
    )
    .then((res) => !!res.contentGeneration);
};

export const fetchSuggestedScenarios = (variables: QuerySuggestedScenariosArgs) => {
  return graphQLClient('designer')
    .request(
      graphql(`
        query SuggestedScenarios(
          $courseId: ID!
          $moduleId: ID!
          $nrPerPage: Int!
          $stepId: ID
          $levelIndex: Int
          $cursor: String
        ) {
          suggestedScenarios(
            courseId: $courseId
            moduleId: $moduleId
            stepId: $stepId
            levelIndex: $levelIndex
            nrPerPage: $nrPerPage
            cursor: $cursor
          ) {
            pageInfo {
              cursor
              hasNextPage
            }
            status
            suggestions {
              description
              id
              options {
                description
                feedback
                title
                consequence
                bestOption
              }
              title
            }
          }
        }
      `),
      variables,
    )
    .then((res) => res.suggestedScenarios);
};
