import { navigation, useNavigationDirection } from '@stellar-lms-frontend/common-utils';
import {
  LearningActivityStep,
  PublishedSurvey,
  Question,
  SurveyAnswerMap,
  TypeMap,
} from '@stellar-lms-frontend/lms-api';

import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { QuestionFullScreen } from '../question/question-full-screen';
import { transformQuestionsToNewSubmission } from './survey-functions';
import { QuestionView } from '../question/question-view';
import { getAllQuestions, toSubmissionDTO } from '../functions';
import { HeaderImage, ScrollContainer } from '@stellar-lms-frontend/ui-components';
import { useTranslation } from 'react-i18next';
import { CurrentCompany } from '@stellar-lms-frontend/lms-graphql';

const NAVIGATION_KEY = 'survey';

type Actions = {
  saveAnswers: (
    learningActivityStepId: string,
    publishedSurveyId: string,
    answers: TypeMap<SurveyAnswerMap>,
  ) => Promise<void>;
  getSurveyById: (sourceId: string, stepId: string) => Promise<PublishedSurvey>;
  getLastSurveySubmission: (learningActivityId: string) => Promise<PublishedSurvey | undefined>;
};

export type SurveyStepViewProps = {
  currentCompany: CurrentCompany;
  step: LearningActivityStep;
  lastLearningActivity?: boolean;
  actions: Actions;
};
export const SurveyStepView: React.FC<SurveyStepViewProps> = ({
  currentCompany,
  step,
  lastLearningActivity = false,
  actions,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'survey-step-view' });
  const [publishedSurvey, setPublishedSurvey] = useState<PublishedSurvey>();
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0);
  const [currentQuestion, setCurrentQuestion] = useState<Question>();
  const [isAlreadySubmitted, setIsAlreadySubmitted] = useState<boolean>(false);
  const { isBackwardNavigation, resetNavigationDirection } = useNavigationDirection();
  const methods = useForm();
  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = methods;

  useEffect(() => {
    if (!publishedSurvey) {
      return;
    }

    if (isBackwardNavigation) {
      setCurrentQuestionIndex(publishedSurvey.questions.length - 1);
      resetNavigationDirection();
    }
  }, [isBackwardNavigation, publishedSurvey, resetNavigationDirection]);

  useEffect(() => {
    if (!publishedSurvey) {
      return;
    }

    const nextQuestion = () => {
      setCurrentQuestionIndex((c) => c + 1);
    };
    const previousQuestion = () => {
      setCurrentQuestionIndex((c) => c - 1);
    };

    const handleSubmitAnswers = async () => {
      handleSubmit(async (data) => {
        const transformedAnswers = toSubmissionDTO(data);
        if (!isAlreadySubmitted) {
          !!publishedSurvey.id &&
            (await actions.saveAnswers(step.id, publishedSurvey.id, transformedAnswers));
        } else if (step.sourceId && isDirty) {
          // get (and create) a new submission to use
          const newSubmission = await actions.getSurveyById(step.sourceId, step.id);

          // transform the answers to fit this new submission instance
          const transformed = transformQuestionsToNewSubmission(
            transformedAnswers,
            publishedSurvey.questions,
            newSubmission.questions,
          );

          // send the answers to the backend
          !!newSubmission.id && (await actions.saveAnswers(step.id, newSubmission.id, transformed));
        }
      })();
    };

    const current = publishedSurvey.questions.at(currentQuestionIndex);
    setCurrentQuestion(current);

    const maxIndex = publishedSurvey.questions.length - 1;
    if (currentQuestionIndex === maxIndex) {
      navigation.popRightPrimary(NAVIGATION_KEY);
      navigation.setRightPrimary(NAVIGATION_KEY, {
        label: lastLearningActivity ? t('finish') : t('next'),
        action: () => {
          handleSubmitAnswers();
          navigation.popRightPrimary(NAVIGATION_KEY);
          navigation.clickRightPrimary();
        },
      });
    }

    if (currentQuestionIndex < maxIndex) {
      navigation.setRightPrimary(NAVIGATION_KEY, {
        label: t('next'),
        action: () => {
          handleSubmitAnswers();
          nextQuestion();
        },
      });
    }

    if (currentQuestionIndex > 0) {
      navigation.setRightSecondary(NAVIGATION_KEY, {
        label: t('previous'),
        action: previousQuestion,
      });
    }

    if (currentQuestionIndex === 0) {
      navigation.popRightSecondary(NAVIGATION_KEY);
    }

    return () => {
      navigation.popLeftPrimary(NAVIGATION_KEY);
      navigation.popRightPrimary(NAVIGATION_KEY);
      navigation.popRightSecondary(NAVIGATION_KEY);
    };
  }, [
    currentQuestionIndex,
    publishedSurvey,
    setCurrentQuestionIndex,
    setCurrentQuestion,
    isAlreadySubmitted,
    step.sourceId,
    step.id,
    lastLearningActivity,
    handleSubmit,
    isDirty,
    actions,
    t,
  ]);

  useEffect(() => {
    const fetchSurvey = async (id: string, activityId: string) => {
      let survey = await actions.getLastSurveySubmission(activityId);

      if (!survey || survey.submitted === false) {
        // It only creates a new survey when there is no existing survey that is not submitted
        survey = await actions.getSurveyById(id, activityId);
        setPublishedSurvey(survey);
        setIsAlreadySubmitted(false);
      } else {
        setIsAlreadySubmitted(true);
        setPublishedSurvey(survey);
      }

      const allQuestions = getAllQuestions(survey?.questions);

      reset(allQuestions);
      const initialIndex = survey?.questions.findIndex((q) => !q.answered);
      setCurrentQuestionIndex(initialIndex !== -1 ? initialIndex : 0);
    };

    if (!step.sourceId) {
      console.error('Survey does not contain sourceId');
      return;
    }

    fetchSurvey(step.sourceId, step.id);
  }, [step, reset, actions]);

  return currentQuestion ? (
    <ScrollContainer scrollOnDesktop={true}>
      <FormProvider {...methods}>
        <div className="flex h-full w-full flex-col">
          <HeaderImage
            isEditing={false}
            companyId={currentCompany.id}
            value={step.headerImage ?? undefined}
          />
          <div className="shrink-0 grow">
            <QuestionView
              key={currentQuestion.id}
              question={currentQuestion}
              showQuestionNumbers={!!publishedSurvey}
              number={currentQuestionIndex + 1}
              total={publishedSurvey?.questions?.length || 0}
              children={(props) => <QuestionFullScreen {...props} />}
            />
          </div>
        </div>
      </FormProvider>
    </ScrollContainer>
  ) : null;
};

export default SurveyStepView;
