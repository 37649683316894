import { navigation, useMediaQuery } from '@stellar-lms-frontend/common-utils';
import { LearningActivityStep } from '@stellar-lms-frontend/lms-api';
import { CrossIcon, DiscussIcon, RoundedIconButton } from '@stellar-lms-frontend/ui-components';
import { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { Discussion } from '..';
import { IFrame } from './iframe';
import { DiscussionViewProps } from '../discussion';
import { useTranslation } from 'react-i18next';
import { CurrentCompany } from '@stellar-lms-frontend/lms-graphql';

const NAVIGATION_KEY = 'iframe-step';

export type IFrameStepViewProps = {
  currentCompany: CurrentCompany;
  step: LearningActivityStep;
  discussionAllowed: boolean;
  actions?: {
    discussion?: DiscussionViewProps['actions'];
  };
};

export const IFrameStepView = ({
  currentCompany,
  step,
  discussionAllowed = true,
  actions,
}: IFrameStepViewProps) => {
  const { t } = useTranslation('translation', { keyPrefix: 'discussion' });

  const { learningActivityId, stepId } = useParams(); // TODO move?
  const isDesktop = useMediaQuery('(min-width: 1028px)');
  const [showDiscussion, setShowDiscussion] = useState(false);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('comment')) {
      setShowDiscussion(true);
    }
  }, [searchParams, setShowDiscussion]);

  useEffect(() => {
    if (!isDesktop) {
      if (showDiscussion && actions?.discussion) {
        navigation.setLeftPrimary(NAVIGATION_KEY, {
          leftIcon: <CrossIcon className="text-text-01" />,
          action: () => setShowDiscussion(!showDiscussion),
        });
        navigation.setTitle(NAVIGATION_KEY, t('discussion-title'));
        navigation.setRightPrimary(NAVIGATION_KEY, { action: () => null }); // dummy to hide learning activity related button
      } else {
        navigation.clearLevel(NAVIGATION_KEY);
      }
    } else {
      discussionAllowed &&
        actions?.discussion &&
        navigation.setDiscussion(NAVIGATION_KEY, {
          label: showDiscussion ? t('hide-discuss-button') : t('discuss-button'),
          leftIcon: <DiscussIcon className="text-text-01" />,
          action: () => setShowDiscussion(!showDiscussion),
        });
    }

    return () => navigation.clearLevel(NAVIGATION_KEY);
  }, [showDiscussion, isDesktop, t, discussionAllowed, actions?.discussion]);

  const renderFloatingDiscussionButton = () => (
    <div className="absolute bottom-8 right-4 rounded-full lg:hidden">
      <RoundedIconButton
        htmlId="discuss-button-mobile"
        size="large"
        onClick={() => setShowDiscussion(true)}
        icon={<DiscussIcon className="text-primary-01" />}
      />
    </div>
  );

  return (
    <>
      {!showDiscussion && discussionAllowed && renderFloatingDiscussionButton()}
      <IFrame
        className={`${
          showDiscussion && !isDesktop ? 'hidden' : 'block'
        } h-full w-full xl:min-w-[500px] `}
        title={step?.title}
        url={step?.secureSourceUrl ? step?.secureSourceUrl : step?.source}
      />
      {showDiscussion && actions?.discussion && learningActivityId && stepId && (
        <Discussion.DiscussionView
          companyId={currentCompany.id}
          actions={actions.discussion}
          learningActivityId={learningActivityId}
          stepId={stepId}
        />
      )}
    </>
  );
};

export default IFrameStepView;
