import { Controller, useFieldArray, useForm } from 'react-hook-form';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {
  AddCard,
  ContentWrapper,
  Divider,
  Edit4Icon,
  FloatingOptions,
  HeaderImage,
  ImageSettings,
  RocketIcon,
  ScrollContainer,
} from '@stellar-lms-frontend/ui-components';
import { EditHeader } from '../../../learning-activity/components/edit-header';
import { LevelForm, ScenarioLevelEdit } from '../scenario-level-edit/scenario-level-edit';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import {
  LearningActivityContext,
  SCENARIO,
  STEP_URI_CREATE_CONST,
  SUGGESTED_LEVELS,
} from '../../../learning-activity';
import React from 'react';
import { useLearningActivityState } from '../../../learning-activity/hooks/use-learning-activity-state';
import { compareObjArrays, getNumbersArray } from '@stellar-lms-frontend/common-utils';
import { CurrentCompany, ScenarioStepInput } from '@stellar-lms-frontend/lms-graphql';
import { ScenarioAIStepEdit } from '../../../scenario-ai-sidebar/scenario-ai-step-edit';
import { useAISuggestions } from '../../../ai-step-edit/use-ai-suggestions';
import { useParams } from 'react-router-dom';
import { transformScenarioToSubmit } from '../../utils';
import { ScenarioActions } from '../../scenario-step';
import { useTranslation } from 'react-i18next';
import { useDefaultHeaderImage } from '../../../../hooks';

type ScenarioEditForms = {
  title: string;
  levels: LevelForm[];
  headerImage?: ImageSettings;
};

export type PreviousLevelsReq = {
  correctOptionText: string;
  levelDescription: string;
};

export type ScenarioEditProps = {
  currentCompany: CurrentCompany;
  value: ScenarioEditForms;
  moduleId?: string;
  actions: ScenarioActions;
};

export const ScenarioEdit: React.FC<ScenarioEditProps> = ({
  currentCompany,
  value,
  actions,
  moduleId,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'scenario-step-view' });
  const { t: tValidation } = useTranslation('translation', { keyPrefix: 'validation' });

  const { courseId, learningActivityId, stepId } = useParams();
  const schema = yup.object({
    title: yup.string().required(tValidation('required')),
  });
  const { setSaveFunc } = useContext(LearningActivityContext);
  const [isAISidebarOpen, setIsAISidebarOpen] = useState(false);
  const [isAddMenuOpen, setIsAddMenuOpen] = useState(false);
  const [prevGenerationReq, setPrevGenerationReq] = useState<PreviousLevelsReq[] | undefined>(
    undefined,
  );

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid },
    setValue,
    getValues,
  } = useForm<ScenarioEditForms>({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: value,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'levels',
  });

  useDefaultHeaderImage(
    stepId,
    () =>
      courseId
        ? (actions.scenario?.getDefaultHeaderImage?.(courseId) ?? Promise.resolve(undefined))
        : Promise.resolve(undefined),
    (headerImage: ImageSettings) => {
      setValue('headerImage', headerImage);
    },
  );

  const {
    suggestions: suggestedScenarios,
    status: suggestedScenariosStatus,
    fetchNextPage,
    hasNextPage,
    refetch,
  } = useAISuggestions({
    queryKey: [learningActivityId, stepId, SCENARIO, SUGGESTED_LEVELS, fields.length],
    fetchFunction: async (args) =>
      await actions.scenario?.fetchSuggestedScenarios?.({
        ...args,
        stepId: stepId === 'create' ? null : stepId,
        levelIndex: fields.length,
      }),
    courseId,
    moduleId,
  });

  useLearningActivityState({ isDirty, isValid });

  const onSave = useCallback(
    async (data: ScenarioStepInput) => {
      if (!learningActivityId) return;

      if (stepId && stepId !== STEP_URI_CREATE_CONST) {
        await actions.scenario?.update?.({ learningActivityId, stepId, input: data });
      } else {
        await actions.scenario?.create?.({ learningActivityId, input: data });
      }
    },
    [actions.scenario, learningActivityId, stepId],
  );

  useEffect(() => {
    setSaveFunc?.(() =>
      handleSubmit((data) => {
        onSave({
          title: data.title,
          levels: transformScenarioToSubmit(data.levels),
          headerImage: data.headerImage?.fileId
            ? {
                fileId: data.headerImage.fileId,
                isFullWidth: data.headerImage.isFullWidth,
              }
            : undefined,
        });
      }),
    );
  }, [handleSubmit, onSave, setSaveFunc]);

  const transformFields = useCallback(
    () =>
      getValues()
        .levels.filter((f) => f.answers.find((a) => a.correct)?.text !== '' || f.context !== '')
        .map((f) => ({
          correctOptionText: f.answers.find((a) => a.correct)?.text ?? '',
          levelDescription: f.context,
        })),
    [getValues],
  );

  const forceReGenerate = useMemo(() => {
    if (isAISidebarOpen && prevGenerationReq) {
      return !compareObjArrays(transformFields(), prevGenerationReq);
    }
    return false;
  }, [isAISidebarOpen, prevGenerationReq, transformFields]);

  const startScenarioGeneration = useCallback(
    async (previousLevels: PreviousLevelsReq[]) => {
      return !!(
        moduleId &&
        courseId &&
        actions?.scenario?.scenarioGeneration?.({
          input: {
            courseId,
            learningActivityId,
            learningActivityStepId: stepId === 'create' ? null : stepId,
            moduleId,
            previousLevels,
          },
        })
      );
    },
    [actions?.scenario, courseId, learningActivityId, moduleId, stepId],
  );

  const startSuggestionsGenerationWrapper = useCallback(() => {
    const transformedFields = transformFields();
    setPrevGenerationReq(transformedFields);
    refetch();
    return startScenarioGeneration(transformedFields);
  }, [startScenarioGeneration, transformFields, refetch]);

  return (
    <ScrollContainer scrollOnDesktop={true}>
      <Controller
        control={control}
        name="headerImage"
        render={({ field }) => (
          <HeaderImage
            {...field}
            companyId={currentCompany.id}
            isEditing={true}
          />
        )}
      />
      <ContentWrapper className="mx-auto space-y-6 pb-6 pt-4">
        <ScenarioAIStepEdit
          forceReGenerate={forceReGenerate}
          isOpen={isAISidebarOpen}
          onClose={() => setIsAISidebarOpen(false)}
          suggestions={suggestedScenarios.map((s) => ({
            ...s,
            options: s.options.flatMap((s) => s ?? []),
          }))}
          fetchMoreSuggestions={fetchNextPage}
          suggestionsStatus={suggestedScenariosStatus}
          hasMoreSuggestions={hasNextPage}
          onAdd={(addedScenarioLevelArr) => {
            const addedScenarioLevel = addedScenarioLevelArr[0];
            if (fields.length === 0) {
              setValue('title', addedScenarioLevel.title);
            }
            append({
              context: addedScenarioLevel.description,
              answers: addedScenarioLevel.options.map((o) => ({
                text: o.description,
                correct: o.bestOption,
                feedback: o.feedback,
              })),
              spacedRepetitionEnabled: true,
            });
          }}
          startSuggestionsGeneration={startSuggestionsGenerationWrapper}
          setIsAISidebarOpen={setIsAISidebarOpen}
          markTipViewed={actions.edit?.markTipViewed}
        />
        <Controller
          control={control}
          name="title"
          render={({ field }) => (
            <EditHeader
              {...field}
              placeholder={t('title.placeholder')}
              onChangeTitle={(e) => field.onChange(e.target.value)}
              error={errors.title?.message}
            />
          )}
        />
        {fields.map((f, index) => (
          <React.Fragment key={f.id}>
            <Divider />
            <Controller
              control={control}
              name={`levels.${index}`}
              render={({ field }) => (
                <ScenarioLevelEdit
                  levelNumber={index + 1}
                  value={field.value}
                  onChange={field.onChange}
                  onDelete={() => remove(getNumbersArray(index, fields.length - index, 1))}
                />
              )}
            />
          </React.Fragment>
        ))}
        <Divider />
        <FloatingOptions
          isOpen={isAddMenuOpen}
          onClose={() => setIsAddMenuOpen(false)}
          widthClassName="w-[600px] lg:w-[640px] 2xl:w-[720px]"
          placement="bottom-start"
          wrappedComponent={
            <AddCard
              isEnabled={true}
              onClick={() => setIsAddMenuOpen(true)}
              label={t('add.label')}
            />
          }
          options={[
            {
              label: t('add.options.ai'),
              className: 'text-primary-01',
              left: <RocketIcon className="text-primary-01 h-6 w-6" />,
              onClick: () => {
                setIsAISidebarOpen(true);
                setIsAddMenuOpen(false);
              },
            },
            {
              label: t('add.options.scratch'),
              left: <Edit4Icon className="h-6 w-6" />,
              onClick: () => {
                append({
                  context: '',
                  answers: [
                    { text: t('option', { count: 1 }), correct: false, feedback: '' },
                    { text: t('option', { count: 2 }), correct: false, feedback: '' },
                  ],
                  spacedRepetitionEnabled: true,
                });
                setIsAddMenuOpen(false);
              },
            },
          ]}
        />
      </ContentWrapper>
    </ScrollContainer>
  );
};
