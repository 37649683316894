import {
  Export2Icon,
  HintDescription,
  IfDefinedWrapper,
  JourneyBoxIllustration,
  LinkButton,
  Modal,
  PrimaryButton,
  ProgressBar,
  Radio,
} from '@stellar-lms-frontend/ui-components';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Course,
  ScormPackage,
  ScormPackageStatus,
  ScormVersion,
} from '@stellar-lms-frontend/lms-graphql';
import { AblyChannelSubscriber } from '../ably';
import { Message } from 'ably';

type ScormExportModalI18N = {
  title: string;
  hint: string;
  versionSelection: {
    title: string;
    explanation: string;
    options: {
      '2004': string;
      '1.2': string;
    };
  };
  loadingMessage: string;
  success: {
    title: string;
    description: string;
  };
  buttons: {
    export: string;
    cancel: string;
    download: string;
  };
};
type Actions = {
  createScormPackage: (courseId: string, version: ScormVersion) => Promise<ScormPackage>;
  getScormPackage: (courseId: string, packageId: string) => Promise<ScormPackage>;
};

export type ScormExportModalProps = {
  isOpen: boolean;
  onClose: () => void;
  currentCourse: Course;
  i18n: ScormExportModalI18N;
  actions: Actions;
};

const SCORM_QUERY = 'SCORM';

export const ScormExportModal: React.FC<ScormExportModalProps> = ({
  isOpen,
  onClose,
  currentCourse,
  i18n,
  actions,
}) => {
  const [scormId, setScormId] = useState<string | undefined>(undefined);
  const [selectedVersion, setSelectedVersion] = useState<ScormVersion>(ScormVersion.Scorm_2004);
  const { courseId } = useParams<{ courseId: string }>();
  const [progress, setProgress] = useState(0);
  const queryClient = useQueryClient();

  const handleOnClose = useCallback(() => {
    onClose();
    setScormId(undefined);
    setSelectedVersion(ScormVersion.Scorm_2004);
  }, [onClose]);

  const handleRealtimeMessage = useCallback(
    (message: Message) => {
      if (message.name === 'create' && message.data.type === 'ScormPackage') {
        queryClient.invalidateQueries([SCORM_QUERY, scormId]);
      }
    },
    [queryClient, scormId],
  );

  const getScormPackageQuery = useQuery(
    [SCORM_QUERY, scormId],
    () => (courseId && scormId ? actions.getScormPackage(courseId, scormId) : undefined),
    {
      enabled: !!scormId,
    },
  );

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (getScormPackageQuery.data?.status === ScormPackageStatus.Creating) {
      interval = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress >= 100) {
            clearInterval(interval);
            return 100;
          }
          return prevProgress + 1;
        });
      }, 600);
    } else {
      setProgress(0);
    }

    return () => clearInterval(interval);
  }, [getScormPackageQuery.data?.status]);

  const buttons = useMemo(() => {
    if (!scormId) {
      return [
        <PrimaryButton
          key={1}
          label={i18n.buttons.export}
          onClick={async () => {
            if (!courseId) {
              return;
            }

            await actions.createScormPackage(courseId, selectedVersion).then((data) => {
              setScormId(data.id);
            });
          }}
        />,
        <LinkButton
          key={2}
          label={i18n.buttons.cancel}
          onClick={handleOnClose}
        />,
      ];
    } else {
      return undefined;
    }
  }, [
    scormId,
    i18n.buttons.export,
    i18n.buttons.cancel,
    handleOnClose,
    courseId,
    actions,
    selectedVersion,
  ]);

  return (
    <IfDefinedWrapper
      variable={currentCourse.realtimeChannel?.id}
      wrapper={(children, variable) => (
        <AblyChannelSubscriber
          channelId={variable}
          callback={handleRealtimeMessage}
        >
          {children}
        </AblyChannelSubscriber>
      )}
    >
      <Modal
        isOpen={isOpen}
        onClose={handleOnClose}
        className="bg-surface-01 font-lexend h-[520px] w-[742px]"
        title={i18n.title}
        buttons={buttons ? { hasShadow: false, buttons } : undefined}
      >
        {!scormId && (
          <div className="space-y-4">
            <HintDescription
              icon={<Export2Icon className="text-informative-01 h-6 w-6" />}
              title={i18n.hint}
            />
            <div className="space-y-2">
              <p className="type-body text-text-01">{i18n.versionSelection.title}</p>
              <p className="type-small text-text-02">{i18n.versionSelection.explanation}</p>
            </div>
            <Radio
              className="text-text-01"
              radioDirection="vertical"
              onChange={(newValue) => setSelectedVersion(newValue as ScormVersion)}
              value={selectedVersion}
              options={[
                { label: i18n.versionSelection.options['2004'], value: ScormVersion.Scorm_2004 },
                { label: i18n.versionSelection.options['1.2'], value: ScormVersion.Scorm_1_2 },
              ]}
              uniqueId="question-multiline-option"
            />
          </div>
        )}
        {getScormPackageQuery.data?.status === ScormPackageStatus.Creating && (
          <div className="flex h-full flex-col items-center justify-center">
            <JourneyBoxIllustration />
            <p className="text-text-02 type-body mb-6 mt-4">{i18n.loadingMessage}</p>
            <ProgressBar
              className="w-full"
              roundedClassName="rounded-full"
              htmlId={'progress-bar-scorm'}
              progress={[progress]}
            />
          </div>
        )}
        {getScormPackageQuery.data?.status === ScormPackageStatus.Done && (
          <div className="flex h-full flex-col items-center justify-center">
            <JourneyBoxIllustration />
            <p className="text-text-02 type-large-emphasis mb-4 mt-5">{i18n.success.title}</p>
            <p className="text-text-02 type-body mb-5">{i18n.success.description}</p>
            <PrimaryButton
              label={i18n.buttons.download}
              onClick={() =>
                getScormPackageQuery.data?.downloadURL &&
                window.open(getScormPackageQuery.data?.downloadURL, '_blank')
              }
            />
          </div>
        )}
      </Modal>
    </IfDefinedWrapper>
  );
};
