import { useQuery, useQueryClient } from '@tanstack/react-query';
import { GraphQLClient } from 'graphql-request';
import { useCallback } from 'react';
import { CompanyQueryVariables, SubscriptionType, graphql } from '../graphql';
import Cookies from 'universal-cookie';
import { useCompanyStore } from './use-company-store';

const QUERY_KEY = 'CURRENT_COMPANY';

export const useCurrentCompany = (client: GraphQLClient) => {
  const queryClient = useQueryClient();

  const companyId = useCompanyStore((state) => state.companyId);

  const query = useQuery(
    [QUERY_KEY, companyId],
    () => (companyId ? getCompany(client, { companyId }) : null),
    {
      enabled: !!companyId,
      staleTime: 60 * 1000, // 1 minute
      cacheTime: 5 * 60 * 10000, // 5 minutes
    },
  );

  const invalidateCache = useCallback(
    (refetch = false) => {
      queryClient.invalidateQueries([QUERY_KEY]);
      if (refetch) {
        query.refetch();
      }
    },
    [queryClient],
  );

  const updateCompanyDetails = useCallback(
    (updatedCompany: { name?: string | null; industry?: string | null }) => {
      queryClient.setQueryData([QUERY_KEY, companyId], (data: any) => {
        const name = updatedCompany.name ? updatedCompany.name : data.name;
        const industry = updatedCompany.industry ? updatedCompany.industry : data.industry;
        return { ...data, name, industry };
      });
    },
    [queryClient],
  );

  const onSelectCompany = useCallback(
    (companyId: string) => {
      setCompanyCookie(companyId);
      useCompanyStore.setState({ companyId });
      invalidateCache();
    },
    [invalidateCache],
  );

  return {
    updateCompanyDetails: updateCompanyDetails,
    invalidateCache: invalidateCache,
    onSelectCompany,
    query: query,
  };
};

export const setCompanyCookie = (companyId: string) => {
  const cookies = new Cookies();
  cookies.set('companyId', companyId, { path: '/' });
};

const eraseCompanyCookie = () => {
  const cookies = new Cookies();
  cookies.remove('companyId');
};

export const getCompanyCookie = () => {
  const cookies = new Cookies();
  return cookies.get('companyId');
};
export type CurrentCompany = {
  id: string;
  name: string;
  industry: string;
  permissions: string[];
  hidePerformanceManagement: boolean;
  language: string;
  realtimeChannel:
    | {
        id: string;
      }
    | undefined;
  subscription?: {
    id: string;
    active: boolean;
    type: SubscriptionType;
    startDate?: Date;
    endDate?: Date;
    billing?: {
      totalCost: number;
      memberCount: number;
      renewalDate?: Date;
    };
  };
  styleProperties?: {
    icon: string;
    logo: string;
    stylesheet: string;
  };
  documentConfiguration?: {
    enabled: boolean;
  };
};

export const getCompany = (
  graphQLClient: GraphQLClient,
  variables: CompanyQueryVariables,
): Promise<CurrentCompany | null> => {
  return graphQLClient
    .request({
      document: graphql(`
        query Company($companyId: ID!) {
          company(id: $companyId) {
            id
            name
            industry
            permissions
            hidePerformanceManagement
            language
            realtimeChannel {
              id
            }
            subscription {
              id
              active
              type
              startDate
              endDate
              billing {
                totalCost
                memberCount
                renewalDate
              }
            }
            styleProperties {
              icon
              logo
              stylesheet
            }
            documentConfiguration {
              enabled
            }
          }
        }
      `),
      variables,
    })
    .then((res) => {
      let subscription = undefined;
      if (res.company.subscription) {
        let billing = undefined;
        if (res.company.subscription.billing) {
          billing = {
            totalCost: res.company.subscription.billing.totalCost,
            memberCount: res.company.subscription.billing.memberCount,
            renewalDate: res.company.subscription.billing.renewalDate
              ? new Date(res.company.subscription.billing.renewalDate * 1000)
              : undefined,
          };
        }

        subscription = {
          id: res.company.subscription.id,
          active: res.company.subscription.active,
          type: res.company.subscription.type,
          startDate: res.company.subscription.startDate
            ? new Date(res.company.subscription.startDate)
            : undefined,
          endDate: res.company.subscription.endDate
            ? new Date(res.company.subscription.endDate)
            : undefined,
          billing: billing,
        };
      }

      let styleProperties = undefined;
      if (res.company.styleProperties) {
        styleProperties = {
          icon: res.company.styleProperties.icon ?? '',
          logo: res.company.styleProperties.logo ?? '',
          stylesheet: res.company.styleProperties.stylesheet ?? '',
        };
      }

      const documentConfiguration = {
        enabled: res.company.documentConfiguration?.enabled ?? false,
      };

      const company: CurrentCompany = {
        id: res.company.id,
        name: res.company.name ?? '',
        industry: res.company.industry ?? '',
        permissions: res.company.permissions ?? [],
        hidePerformanceManagement: res.company.hidePerformanceManagement ?? false,
        language: res.company.language ?? 'en',
        realtimeChannel: res.company.realtimeChannel
          ? {
              id: res.company.realtimeChannel.id,
            }
          : undefined,
        subscription: subscription,
        styleProperties: styleProperties,
        documentConfiguration: documentConfiguration,
      };

      return company;
    })
    .catch((e) => {
      eraseCompanyCookie();
      window.location.reload();
      return null;
    });
};
