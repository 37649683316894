import { LearningActivityStep } from '@stellar-lms-frontend/lms-api';
import {
  CreateScenarioStepMutation,
  CreateScenarioStepMutationVariables,
  CurrentCompany,
  MarkTipViewedMutationVariables,
  MutationScenarioGenerationArgs,
  QuerySuggestedScenariosArgs,
  ScenarioStepQuery,
  ScenarioStepQueryVariables,
  SuggestedScenarios,
  UpdateScenarioStepMutation,
  UpdateScenarioStepMutationVariables,
} from '@stellar-lms-frontend/lms-graphql';
import { ScenarioEdit } from './components/scenario-edit/scenario-edit';
import { useParams } from 'react-router-dom';
import { SCENARIO, STEP_URI_CREATE_CONST } from '../learning-activity';
import { useQuery } from '@tanstack/react-query';
import { xxHash } from '@stellar-lms-frontend/common-utils';
import { ScenarioView, ScenarioViewProps } from './components/scenario-view/scenario-view';
import { ImageSettings } from '@stellar-lms-frontend/ui-components';

export type ScenarioActions = {
  edit?: {
    markTipViewed?: (variables: MarkTipViewedMutationVariables) => void;
  };
  scenario?: {
    fetch?: (variables: ScenarioStepQueryVariables) => Promise<ScenarioStepQuery>;
    create?: (
      variables: CreateScenarioStepMutationVariables,
    ) => Promise<CreateScenarioStepMutation>;
    update?: (
      variables: UpdateScenarioStepMutationVariables,
    ) => Promise<UpdateScenarioStepMutation>;
    scenarioGeneration?: (variables: MutationScenarioGenerationArgs) => Promise<boolean>;
    getDefaultHeaderImage?: (courseId: string) => Promise<ImageSettings | undefined>;
    fetchSuggestedScenarios?: (
      variables: QuerySuggestedScenariosArgs,
    ) => Promise<SuggestedScenarios>;
  };
  publishedScenario: ScenarioViewProps['actions'];
};

type ScenarioStepProps = {
  currentCompany: CurrentCompany;
  actions: ScenarioActions;
  isEditing?: boolean;
  step?: LearningActivityStep;
  moduleId?: string;
};

export const ScenarioStep: React.FC<ScenarioStepProps> = ({
  currentCompany,
  actions,
  isEditing,
  step,
  moduleId,
}) => {
  const { learningActivityId, stepId } = useParams();

  const isQueryEnabled = !!(learningActivityId && stepId && stepId !== STEP_URI_CREATE_CONST);
  const { data: scenarioQueryData } = useQuery(
    [learningActivityId, stepId, SCENARIO],
    () =>
      isQueryEnabled ? (actions?.scenario?.fetch?.({ learningActivityId, stepId }) ?? null) : null,
    {
      enabled: isQueryEnabled,
      refetchOnWindowFocus: false,
      select: (data) => {
        if (data?.learningActivityStep && data.learningActivityStep.__typename === 'ScenarioStep') {
          const editData = {
            title: data.learningActivityStep.title ?? '',
            levels:
              data.learningActivityStep.levels?.map((l) => ({
                ...l,
                context: l.text,
                answers: l?.answers ?? [],
                spacedRepetitionEnabled: l.spacedRepetitionEnabled,
              })) ?? [],
            headerImage: data.learningActivityStep.headerImage ?? undefined,
          };
          return {
            editData: editData,
            readLevels: data.learningActivityStep.levels,
          };
        }

        return undefined;
      },
    },
  );

  if (isEditing) {
    if (!scenarioQueryData) {
      return null;
    }
    return (
      <ScenarioEdit
        key={`${xxHash(JSON.stringify(step))}-${xxHash(JSON.stringify(scenarioQueryData))}`}
        currentCompany={currentCompany}
        moduleId={moduleId}
        value={scenarioQueryData.editData}
        actions={actions}
      />
    );
  } else if (step) {
    return (
      <ScenarioView
        currentCompany={currentCompany}
        step={step}
        actions={{
          ...actions.publishedScenario,
          evaluateWithoutSubmission: async (levelIndex, answer) => {
            const levelAnswers = scenarioQueryData?.readLevels?.[levelIndex]?.answers ?? [];
            const selectedAnswer = levelAnswers.find((a) => a.text === answer.userInput);
            return {
              answers: levelAnswers.map((a) => ({
                answerCorrect: a.correct,
                answerId: a.id,
                answerText: a.text,
                id: a.id,
                userAnswered: a.text === answer.userInput,
                userAnsweredCorrect: a.text === answer.userInput && a.correct,
              })),
              feedback: selectedAnswer?.feedback ?? '',
            };
          },
        }}
      />
    );
  } else {
    console.log('no step');
    return null;
  }
};
